import Action from "./action-types";
import { IAppContextState } from "../shared/types";

export const initializer =
  (lsKey: string) =>
  (initialValue: IAppContextState): IAppContextState => ({
    user: localStorage.getItem(lsKey) || initialValue,
  });

const reducer = (state: IAppContextState, action: any): IAppContextState => {
  switch (action.type) {
    case Action.SetUser:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
