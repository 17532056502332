import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Table,
  TableMode,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async ({ ...pagination }: WithPagination<{}>) => {
  const res = await axios.get<Paginated<Table>>(
    withQueryParams("/api/tables", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Table>(`/api/tables/${id}`);
  return res.data;
};

type CreateTable = {
  name: string;
  description: string;
  tableModeId: number;
};

const create = async (payload: CreateTable) => {
  const res = await axios.post<Table>("/api/tables", payload);

  return res.data;
};

const update = async ({ id, ...translation }: Table) => {
  const res = await axios.patch<Table>(`/api/tables/${id}`, translation);

  return res.data;
};

const del = async (payload: Table) => {
  await axios.delete<Table>(`/api/tables/${payload.id}`);
  return payload;
};

const getTableModes = async () => {
  const res = await axios.get<Paginated<TableMode>>("/api/table-modes");
  return res.data;
};

type RegisterTable = {
  name: string;
  description: string;
  user: string;
};

const registerTable = async (payload: RegisterTable) => {
  const res = await axios.post<Table>(
    "/api/tables/register-pos-table",
    payload
  );
  return res.data;
};

const confirmRegistration = async (id: number) => {
  const res = await axios.post<Table>(
    `/api/tables/confirm-pos-table/${id}`,
    null
  );
  return res.data;
};

const TableService = {
  getAll,
  getById,
  create,
  update,
  del,
  getTableModes,
  registerTable,
  confirmRegistration,
};

export default TableService;
