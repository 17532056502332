import { useQuery, QueryOptions } from "react-query";
import { PaginationInput, ApiGetResponse as Paginated } from "../shared/types";
import useContainerPagination from "./useContainerPagination";

export interface UsePaginatedQueryProps<T>
  extends Partial<Omit<QueryOptions<Paginated<T>>, "queryFn">> {
  queryFn: (pagination: PaginationInput) => Promise<Paginated<T>>;
  initialPaginationConfig?: PaginationInput;
}

const usePaginatedQuery = <T>(props: UsePaginatedQueryProps<T>) => {
  const { queryKey, queryFn, ...queryOptions } = props;
  const { page, pageSize, setPage, setPageSize } = useContainerPagination();

  const query = useQuery(
    [queryKey, page, pageSize],
    () => props.queryFn({ page, pageSize }),
    { ...queryOptions, keepPreviousData: true }
  );

  return { query, pagination: { page, pageSize, setPage, setPageSize } };
};

export default usePaginatedQuery;
