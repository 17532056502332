import { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "../../shared";
import {
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import { useUpsert } from "../../hooks";
import { ProductService } from "../../services";
import { Product, UpsertAction, UpsertForm } from "../../shared/types";

const createProductPayload = {
  price: Yup.number().required("Obavezno polje"),
  name: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
  description: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
};
const CreateProductSchema = Yup.object().shape(createProductPayload);
const UpdateProductSchema = Yup.object().shape({
  id: Yup.number().required(),
  ...createProductPayload,
});

const defaultInitialProduct = {
  name: "",
  description: "",
  price: 0,
  sku: "",
  categories: [] as Product["categories"],
  ingredients: [] as Product["ingredients"],
  options: [] as Product["options"],
};

const UpsertProductForm = (props: UpsertForm<Product>) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const { create, update } = useUpsert(
    "products",
    ProductService.create,
    ProductService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateProductSchema : UpdateProductSchema}
      initialValues={isNew ? defaultInitialProduct : props.initialValue}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        const isUpdate = "id" in values;

        if (isUpdate) await update(values);
        else {
          await create({
            ...values,
            categories: values.categories.map((c) => c.id),
            ingredients: values.ingredients.map((i) => i.id),
            options: values.options.map((o) => o.id),
          });
        }

        resetForm();
        if (!keepOpen) props.onClose();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
        setFieldValue,
      }) => {
        const change = (
          name: string,
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        const changeSelect = (name: string) => (option: any) => {
          setFieldValue(name, option);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} Proizvod</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    value={values.name}
                    helperText={<>{touched.name ? errors.name : ""}</>}
                    error={touched.name && Boolean(errors.name)}
                    onChange={change.bind(null, "name")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="description"
                    name="description"
                    label="Opis"
                    fullWidth
                    value={values.description}
                    helperText={
                      <>touched.description ? errors.description : ""</>
                    }
                    error={touched.description && Boolean(errors.description)}
                    onChange={change.bind(null, "description")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="price"
                    name="price"
                    label="Cijena"
                    fullWidth
                    value={values.price}
                    helperText={touched.price ? errors.price : ""}
                    error={touched.price && Boolean(errors.price)}
                    onChange={change.bind(null, "price")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">HRK</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="sku"
                    name="sku"
                    label="Šifra"
                    fullWidth
                    value={values.sku}
                    onChange={change.bind(null, "sku")}
                    helperText={touched.sku ? errors.sku : ""}
                    error={touched.sku && Boolean(errors.sku)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    dropdownFor="category"
                    getOptionLabel={(option) => option.name.hr}
                    inputLabel="Kategorije"
                    onChange={changeSelect("categories")}
                    value={values.categories}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    dropdownFor="ingredient"
                    getOptionLabel={(option) => option.name.hr}
                    inputLabel="Sastojci"
                    onChange={changeSelect("ingredients")}
                    value={values.ingredients}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    dropdownFor="option"
                    getOptionLabel={(option) => {
                      console.log(option);
                      return option.name.hr;
                    }}
                    inputLabel="Opcije"
                    onChange={changeSelect("options")}
                    value={values.options}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Odustani</Button>
              <Button onClick={() => handleSubmit()}>{actionText}</Button>
              {isNew && (
                <Button
                  onClick={(e) => {
                    handleSubmit();
                    setKeepOpen(true);
                  }}
                >
                  Spremi i dodaj novi
                </Button>
              )}
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertProductForm;
