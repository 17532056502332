import { useState, useEffect, useContext, useCallback } from "react";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Loader } from "../../shared";
import { Order, UpsertAction } from "../../shared/types";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import OptionsAndIngredientsTable from "./components/OptionsAndIngredientsTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, List, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TableBarIcon from "@mui/icons-material/TableBar";
import PersonIcon from "@mui/icons-material/Person";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { withStyles } from "@mui/styles";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import AddIcon from "@mui/icons-material/Add";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { SignalRContext } from "../../context/SignalR";
import { GetColumns } from "../../components/DataTable/DataTable";
import { usePaginatedQuery, useUpsertForm } from "../../hooks";
import { OrderService } from "../../services";
import { useQueryClient } from "react-query";
import UpsertOrderForm from "./UpsertOrderForm";
import OrderStatusButton from "./components/OrderStatusButton";

const styles = {
  cardContent: {
    padding: "0 !important",
  },
};

type Status = {
  color: string;
  label: string;
};

const primary = "#3F51B5";
const info = "#62B1F6";
const success = "#5cb85c";
const danger = "#d9534f";
const warning = "#f0ad4e";
const light = "#ccc";

const status: Status[] = [
  { color: primary, label: "Novo" },
  { color: info, label: "U pripremi" },
  { color: light, label: "Spremno" },
  { color: warning, label: "Isporučeno" },
  { color: success, label: "Naplaćeno" },
  { color: danger, label: "Otkazano" },
];

const statusErrorMsg = [
  "Nema novih narudžbi",
  "Nema narudžba u pripremi",
  "Nema spremnih narudžbi",
  "Nema isporucenih narudžbi",
  "Nema naplaćenih narudžbi",
  "Nema otkazanih narudžbi",
];

// const empty = {
//   table: { id: "", name: "" },
//   waiter: { name: "", surname: "" },
//   orderItems: [],
//   status: 7,
// } as any as Order;

const renderStatusLabel = (index: number) => status[index]?.label || "";
const getStatusColor = (index: number) => status[index]?.color || "";

const getColumns: GetColumns<
  Order,
  { handleChange: (e: SelectChangeEvent) => void; statusFilter: string }
> = ({ handleChange, statusFilter }) => [
  {
    name: "Šifra",
    selector: () => "id",
    width: "100px",
    format: (row) => `${row.table.id}`,
  },
  {
    name: "Stol",
    selector: () => "table",
    width: "150px",
    format: (row) => `${row.table.name}`,
  },
  {
    name: "Konobar",
    selector: () => "waiter",
    width: "200px",
    format: (row) =>
      row.waiter ? `${row.waiter.name} ${row.waiter.surname}` : `N/A`,
  },
  {
    name: "Proizvodi",
    selector: () => "orderItems",
    grow: 1,
    cell: (row) => {
      return (row.orderItems || []).map((orderItem) => (
        <div>{`${orderItem.product.name} - ${orderItem.quantity}`}</div>
      ));
    },
  },
  {
    name: "Status",
    selector: () => "status",
    width: "150px",
    cell: (row) => renderStatusLabel(row.status),
  },
  {
    name: (
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-helper-label">Filter</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={statusFilter}
          label="Filter"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>bez filtera</em>
          </MenuItem>
          {status.map(({ label }, i) => (
            <MenuItem value={i}>{label}</MenuItem>
          ))}
        </Select>
        {/* <FormHelperText>With label + helper text</FormHelperText> */}
      </FormControl>
    ),
    width: "150px",
    cell: (row: Order) => {
      const statusLabel = renderStatusLabel(row.status);
      if (row.status === 5 || row.status === 6) {
        return;
      }

      return (
        <OrderStatusButton
          order={row}
          statusColor={getStatusColor(row.status)}
          statusLabel={statusLabel}
        />
      );
    },
    button: true,
  },
];

const OrderContainer = ({ classes }: { classes: any }) => {
  const client = useQueryClient();
  const { query, pagination } = usePaginatedQuery({
    queryKey: "orders",
    queryFn: OrderService.getAll,
  });
  const { data, isLoading, refetch } = query;
  const { pageSize, setPage, setPageSize } = pagination;
  const { open, close } = useUpsertForm();

  const [currentTab, setCurrentTab] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const { connection, muteNotifications, setMuteNotifications } =
    useContext(SignalRContext);

  const handleChange = (e: SelectChangeEvent) => {
    setStatusFilter(e.target.value);
  };

  const handleTabChange = (e: React.SyntheticEvent, selectedTab: number) =>
    setCurrentTab(selectedTab);

  const toggleMute = () => setMuteNotifications(!muteNotifications);

  const handleOrderStatusUpdate = useCallback(
    (res: Order) => {
      if (!data?.results) return refetch();

      const orders = [...data.results];
      const orderIndex = orders.findIndex((o) => o.id === res.id);

      if (orderIndex >= 0) {
        orders[orderIndex].status = res.status;
        client.setQueryData("orders", { ...data, results: orders });
      }
    },
    [data, client, refetch]
  );

  useEffect(() => {
    connection?.on("refreshOrders", refetch);
    connection?.on("orderStatusUpdate", handleOrderStatusUpdate);

    return () => {
      connection?.off("refreshOrders", refetch);
    };
  }, [connection, refetch, handleOrderStatusUpdate]);

  const filteredData = data?.results.filter(
    (o) => o.status === parseInt(statusFilter)
  );

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container alignItems="center">
        <Typography sx={{ flexGrow: 1 }}>Narudžbe</Typography>
        {/* 
          <Button
            // sx={{ ml: 100 }}
            variant='contained'
            color='primary'
            onClick={createOrder}
          >
            Simuliraj narudžbu
          </Button> 
        */}
        <Button color="primary" onClick={toggleMute}>
          Notifications
          {muteNotifications ? (
            <NotificationsOffIcon sx={{ ml: 1 }} />
          ) : (
            <NotificationsActiveIcon sx={{ ml: 1 }} />
          )}
        </Button>
      </Grid>
      <Box sx={{ width: "100%", pl: 4, pr: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label={<ViewListIcon />} />
            <Tab label={<GridViewIcon />} />
            <Button
              key="add"
              onClick={() =>
                open(
                  <UpsertOrderForm
                    onClose={close}
                    action={UpsertAction.create}
                  />
                )
              }
            >
              <AddIcon />
              Dodaj narudžbu
            </Button>
          </Tabs>
        </Box>
        {currentTab === 0 && (
          <>
            <div>
              <DataTable
                columns={getColumns({
                  open,
                  close,
                  handleChange,
                  statusFilter,
                })}
                data={filteredData || []}
                paginationPerPage={pageSize}
                pagination
                paginationServer
                onChangeRowsPerPage={setPageSize}
                onChangePage={setPage}
                paginationTotalRows={pageSize}
                progressComponent={<Loader />}
                progressPending={isLoading}
                noDataComponent={
                  <Typography m={2}>Nema dostupnih narudžbi</Typography>
                }
                // OptionsAndIngredientsTables
                // OptionsAndIngredientsTablesComponent={({ data }) => (
                //   <OptionsAndIngredientsTable
                //     padding={4}
                //     data={data}
                //     isLoading={isLoading}
                //   />
                // )}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                // OptionsAndIngredientsTablesHideExpander={false}
              />
            </div>
          </>
        )}
        {currentTab === 1 && (
          <Stack
            direction="row"
            spacing={1}
            sx={{ overflow: "auto", pl: 1, pr: 1 }}
          >
            {status.map(({ color, label }, index) => (
              <Grid container>
                <Paper
                  sx={{
                    overflowY: "overlay",
                    width: 300,
                    height: "39vh",
                    maxHeight: "78vh",
                  }}
                >
                  <Typography
                    variant="h6"
                    p={1}
                    sx={{
                      backgroundColor: color,
                      color: "white",
                    }}
                  >
                    {label}
                  </Typography>
                  {data?.results.filter((order) => order.status === index)
                    .length ? (
                    data.results
                      .filter((order) => order.status === index)
                      .map((order) => (
                        <Card sx={{ m: 1 }}>
                          <CardHeader
                            sx={{
                              color: color,
                            }}
                            title={
                              <Typography variant="subtitle2">
                                {order.orderItems[0].product.name.hr}
                              </Typography>
                            }
                          />
                          <CardContent className={classes.cardContent}>
                            <List dense={true}>
                              <ListItem>
                                <ListItemIcon>
                                  <TableBarIcon />
                                </ListItemIcon>
                                <ListItemText primary={order.table.name} />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <PersonIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${order.waiter.name} ${order.waiter.surname}`}
                                />
                              </ListItem>
                            </List>

                            <OptionsAndIngredientsTable
                              padding={2}
                              data={data.results}
                              isLoading={isLoading}
                            />
                          </CardContent>
                          <CardActions disableSpacing></CardActions>
                        </Card>
                      ))
                  ) : (
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                      {statusErrorMsg[index]}
                    </Grid>
                  )}
                </Paper>
              </Grid>
            ))}
          </Stack>
        )}
      </Box>
    </Paper>
  );
};

export default withStyles(styles)(OrderContainer);
