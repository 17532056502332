import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Language,
  WithoutId,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (pagination: WithPagination<{}>) => {
  const res = await axios.get<Paginated<Language>>(
    withQueryParams("/api/languages", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Language>(`/api/languages/${id}`);
  return res.data;
};

const create = async (payload: WithoutId<Language>) => {
  const res = await axios.post<Language>("/api/languages", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Language) => {
  const res = await axios.patch<Language>(`/api/languages/${id}`, payload);
  return res.data;
};

const del = async (payload: Language) => {
  await axios.delete<Language>(`/api/languages/${payload.id}`);
  return payload;
};

const LanguageService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default LanguageService;
