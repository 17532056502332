import React from "react";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Async from "react-select/async";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Table, UpsertAction, UpsertForm } from "../../shared/types";
import { TableService } from "../../services";
import { useUpsert } from "../../hooks";
import { useQuery } from "react-query";

const createTablePayload = {
  name: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
};
const CreateTableSchema = Yup.object().shape(createTablePayload);
const UpdateTableSchema = Yup.object().shape({
  id: Yup.number().required(),
  ...createTablePayload,
});

const defaultInitialTable = {
  name: "",
  description: "",
  tableModeId: "",
  tableRegistration: "",
  tableMode: {},
} as const;

const TableForm: React.FC<UpsertForm<Table>> = (props) => {
  const { create, update } = useUpsert(
    "tables",
    TableService.create,
    TableService.update
  );
  const {
    data: tableModes,
    isLoading,
    isError,
  } = useQuery("table-modes", TableService.getTableModes);

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  if (isLoading) return null;
  if (isError || !tableModes) return null;

  return (
    <Formik
      validationSchema={isNew ? CreateTableSchema : UpdateTableSchema}
      initialValues={isNew ? defaultInitialTable : props.initialValue}
      enableReinitialize={true}
      onSubmit={async (payload, { resetForm }) => {
        try {
          const isUpdate = !isNew && "id" in payload;
          if (isUpdate) await update(payload);
          else {
            await create({
              name: payload.name,
              description: payload.description,
              tableModeId: parseInt(`${payload.tableModeId}`),
            });
          }

          resetForm();
          props.onClose();
        } catch (err) {
          // no need to handle error, it's handled in mutation
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldValue,
        setFieldTouched,
      }) => {
        const change = (name: string, e: React.ChangeEvent) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} stol</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    value={values.name}
                    helperText={<>{touched.name ? errors.name : ""}</>}
                    error={touched.name && Boolean(errors.name)}
                    onChange={change.bind(null, "name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="description"
                    name="description"
                    label="Opis"
                    fullWidth
                    value={values.description}
                    onChange={change.bind(null, "price")}
                    helperText={
                      <>{touched.description ? errors.description : ""}</>
                    }
                    error={touched.description && Boolean(errors.description)}
                  />
                </Grid>
                {!!values.tableRegistration && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.tableRegistration.isConfirmed}
                          // onChange={toggleTableRegistration}
                        />
                      }
                      label="Potvrđen"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <InputLabel htmlFor={"tableMode"}>Vrsta</InputLabel>
                  <Async
                    id="tableMode"
                    defaultOptions
                    name="tableMode"
                    cacheOptions
                    loadOptions={(_, callback) =>
                      callback(
                        tableModes.results.map(({ name, id }) => ({
                          label: name,
                          value: id,
                        }))
                      )
                    }
                    onChange={(val) => {
                      setFieldValue("tableMode", val);
                      setFieldTouched("tableMode", true, false);
                    }}
                    value={values.tableMode}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default TableForm;
