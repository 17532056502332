import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Ingredient, UpsertAction } from "../../shared/types";
import { DataTable } from "../../components";
import { IngredientService } from "../../services";
import UpsertIngredientForm from "./UpsertIngredientForm";
import MenuOptions from "../../shared/MenuOptions/new";
import { GetColumns } from "../../components/DataTable/DataTable";

const getColumns: GetColumns<Ingredient> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-ingredient",
            sourceQueryKey: "ingredients",
            mutationFn: () => IngredientService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete ingredient");
            },
          }}
          onEdit={() =>
            open(
              <UpsertIngredientForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Naziv",
    selector: (row) => row.name.hr,
  },
  {
    name: "Opis",
    selector: (row) => row.description.hr,
  },
];
const IngredientContainer = () => {
  // const handleRowClick = (row: Ingredient) =>
  //   navigate(`/ingredients/${row.id}`);
  return (
    <Paper>
      <DataTable
        title="Sastojci"
        columns={getColumns}
        queryOptions={{
          queryKey: "ingredients",
          queryFn: IngredientService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertIngredientForm
                action={UpsertAction.create}
                onClose={close}
              />
            ),
        }}
        noData={<Typography m={2}>Nema dostupnih sastojaka</Typography>}
      />
    </Paper>
  );
};

export default IngredientContainer;
