import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AppContext from "../../context/context";

const PrivateRoute = ({ redirectPath = "/login", children }) => {
  const { state } = useContext(AppContext);
  debugger;
  if (!state?.user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
