import { useContext, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import Avatar from "@mui/material/Avatar";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { Formik, Form } from "formik";
import AppContext from "../../context/context";
import { tokenService } from "../../services";
import { useNavigate } from "react-router";
import { Theme } from "@mui/material";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © "}
    <Link color="inherit" href="https://smalt-it.hr/">
      SMALT IT d.o.o.
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

const NewTableSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "Prekratak unos")
    .max(50, "Predug unos!")
    // .email('Neispravna email adresa')
    .required("Obavezno polje"),
  password: Yup.string().required("Obavezno polje"),
});

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginContainer = () => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { setUser } = useContext(AppContext);
  const navigate = useNavigate();

  const login = async (values: { username: string; password: string }) => {
    try {
      const res = await axios.post(`/Account/Login`, {
        username: values.username,
        password: values.password,
      });
      const { jwtToken } = res.data;

      setUser(jwtToken);
      tokenService.setToken(jwtToken);

      setIsLoading(false);
      navigate("/");
    } catch (err) {
      setIsLoading(false);
      toast.error("Korisničko ime ili lozinka nije točna!");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Prijavite se
        </Typography>
        <Formik
          validationSchema={NewTableSchema}
          enableReinitialize
          initialValues={{ username: "", password: "" }}
          onSubmit={login}
        >
          {(props) => {
            const { values, touched, errors, handleChange, setFieldTouched } =
              props;

            const change = (name: string, e: React.ChangeEvent) => {
              e.persist();
              handleChange(e);
              setFieldTouched(name, true, false);
            };

            return (
              <Form>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Korisničko ime"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={values.username}
                  helperText={touched.username ? errors.username : ""}
                  error={touched.username && Boolean(errors.username)}
                  onChange={change.bind(null, "username")}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Lozinka"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={values.password}
                  helperText={touched.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)}
                  onChange={change.bind(null, "password")}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Zapamti me"
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={(e) => {
                    // handleSubmit();
                    // setIsLoading(true);
                    instance.loginPopup(loginRequest);
                  }}
                  disabled={!(values.username && values.password)}
                  loading={isLoading}
                >
                  Prijava
                </LoadingButton>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Zaboravili ste lozinku?
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default LoginContainer;
