import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperTextProps } from "@mui/material";
import { UpsertAction, UpsertForm, Waiter } from "../../shared/types";
import { useUpsert } from "../../hooks";
import { WaiterService } from "../../services";

const CreateWaiterPayload = {
  name: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno polje"),
  surname: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
  username: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
  internalId: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
};

const CreateWaiterSchema = Yup.object().shape(CreateWaiterPayload);
const UpdateWaiterSchema = Yup.object().shape({
  id: Yup.number().required(),
  ...CreateWaiterPayload,
});

const defaultInitialWaiter = {
  name: "",
  internalId: "",
  username: "",
  surname: "",
} as const;

const UpsertWaiterForm: React.FC<UpsertForm<Waiter>> = (props) => {
  const { create, update } = useUpsert(
    "waiters",
    WaiterService.create,
    WaiterService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateWaiterSchema : UpdateWaiterSchema}
      initialValues={isNew ? defaultInitialWaiter : props.initialValue}
      enableReinitialize={true}
      onSubmit={async (payload, { resetForm }) => {
        try {
          const isUpdate = !isNew && "id" in payload;
          await (isUpdate ? update(payload) : create(payload));

          resetForm();
          props.onClose();
        } catch (err) {
          // no need to handle error, it's handled in mutation
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (
          name: string,
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} konobara</DialogTitle>
            <DialogContent style={{ paddingTop: "20px" }}>
              {/* <DialogContentText>
                Odaberite naziv i moguce vrijednosti za opciju
              </DialogContentText> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{
                      "data-testid": "waiter-form__input",
                    }}
                    id="name"
                    name="name"
                    label="Ime"
                    fullWidth
                    value={values.name}
                    helperText={<>{touched.name ? errors.name : ""}</>}
                    error={touched.name && Boolean(errors.name)}
                    onChange={change.bind(null, "name")}
                    FormHelperTextProps={
                      {
                        "data-testid": "waiter-form__helper-text",
                      } as Partial<FormHelperTextProps>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{
                      "data-testid": "waiter-form__input",
                    }}
                    id="surname"
                    name="surname"
                    label="Prezime"
                    fullWidth
                    value={values.surname}
                    onChange={change.bind(null, "surname")}
                    helperText={<>{touched.surname ? errors.surname : ""}</>}
                    error={touched.surname && Boolean(errors.surname)}
                    FormHelperTextProps={
                      {
                        "data-testid": "waiter-form__helper-text",
                      } as Partial<FormHelperTextProps>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputProps={{
                      "data-testid": "waiter-form__input",
                    }}
                    id="username"
                    name="username"
                    label="Korisničko ime"
                    value={values.username}
                    fullWidth
                    onChange={change.bind(null, "username")}
                    helperText={<>{touched.username ? errors.username : ""}</>}
                    error={touched.username && Boolean(errors.username)}
                    FormHelperTextProps={
                      {
                        "data-testid": "waiter-form__helper-text",
                      } as Partial<FormHelperTextProps>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputProps={{
                      "data-testid": "waiter-form__input",
                    }}
                    id="internalId"
                    name="internalId"
                    label="Šifra"
                    fullWidth
                    value={values.internalId}
                    onChange={change.bind(null, "internalId")}
                    helperText={
                      <>{touched.internalId ? errors.internalId : ""}</>
                    }
                    error={touched.internalId && Boolean(errors.internalId)}
                    FormHelperTextProps={
                      {
                        "data-testid": "waiter-form__helper-text",
                      } as Partial<FormHelperTextProps>
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Odustani</Button>
              <Button onClick={() => handleSubmit()}>Spremi</Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertWaiterForm;
