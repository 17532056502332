import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Category,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (
  pagination: WithPagination<{ categoryNameFilter?: number }>
) => {
  const res = await axios.get<Paginated<Category>>(
    withQueryParams("/api/categories", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Category>(`/api/categories/${id}`);
  return res.data;
};

interface CreateCategoryPayload {
  name: string;
}

const create = async (payload: CreateCategoryPayload) => {
  const res = await axios.post<Category>("/api/categories", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Category) => {
  const res = await axios.patch<Category>(`/api/categories/${id}`, payload);
  return res.data;
};

const del = async (payload: Category) => {
  await axios.delete<Category>(`/api/categories/${payload.id}`);
  return payload;
};

const ProductCategoryService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default ProductCategoryService;
