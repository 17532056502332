import { Paper, Typography } from "@mui/material";
import { Currency, UpsertAction } from "../shared/types";
import MenuOptions from "../shared/MenuOptions/new";
import { DataTable } from "../components";
import { CurrencyService } from "../services";
import { GetColumns } from "../components/DataTable/DataTable";
import UpsertCurrencyForm from "./UpsertCurrencyForm";

const getColumns: GetColumns<Currency> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-currency",
            sourceQueryKey: "currencies",
            mutationFn: () => CurrencyService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete currency");
            },
          }}
          onEdit={() =>
            open(
              <UpsertCurrencyForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Kod",
    selector: (row) => row.code,
    width: "200px",
  },
  {
    name: "Naziv",
    selector: (row) => row.name.hr,
    width: "200px",
  },
];

const CurrencyContainer = () => {
  return (
    <Paper>
      <DataTable
        title="Valute"
        columns={getColumns}
        queryOptions={{
          queryKey: "currencies",
          queryFn: CurrencyService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertCurrencyForm
                action={UpsertAction.create}
                onClose={close}
              />
            ),
        }}
        noData={<Typography m={2}>Nema dostupnih valuta</Typography>}
      />
    </Paper>
  );
};

export default CurrencyContainer;
