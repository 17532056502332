import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Waiter, UpsertAction } from "../../shared/types";
import DataTable, { GetColumns } from "../../components/DataTable/DataTable";
import MenuOptions from "../../shared/MenuOptions/new";
import { WaiterService } from "../../services";
import UpsertWaiterForm from "./UpsertWaiterForm";

const getColumns: GetColumns<Waiter> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-waiter",
            sourceQueryKey: "waiters",
            mutationFn: () => WaiterService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete waiter");
            },
          }}
          onEdit={() =>
            open(
              <UpsertWaiterForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Ime",
    selector: (row) => row.name,
  },
  {
    name: "Prezime",
    selector: (row) => row.surname,
  },
  {
    name: "Korisničko ime",
    selector: (row) => row.username,
  },
  {
    name: "Šifra",
    selector: (row) => row.internalId,
  },
];

const WaiterContainer: React.FC = () => {
  return (
    <Paper>
      <DataTable
        title="Konobari"
        columns={getColumns}
        queryOptions={{
          queryKey: "waiters",
          queryFn: WaiterService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertWaiterForm action={UpsertAction.create} onClose={close} />
            ),
        }}
        noData={
          <Typography data-testid="waiter-table__empty-message" m={2}>
            Nema dostupnih konobara
          </Typography>
        }
      />
    </Paper>
  );
};

export default WaiterContainer;
