import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Currency, UpsertAction, UpsertForm } from "../shared/types";
import { useUpsert } from "../hooks";
import { CurrencyService } from "../services";

const CreateCurrencyPayload = {
  name: Yup.object({
    hr: Yup.string()
      .min(3, "Prekratak naziv")
      .max(50, "Predug naziv!")
      .required("Obavezno"),
    en: Yup.string(),
  }),
  code: Yup.string()
    .required("Obavezno")
    .length(3, "Kod mora sadržavati 3 znaka"),
};

const CreateCurrencySchema = Yup.object().shape(CreateCurrencyPayload);
const UpdateCurrencySchema = Yup.object().shape({
  ...CreateCurrencyPayload,
  id: Yup.number().required(),
});

const defaultInitialCurrency = {
  name: { hr: "", en: "" },
  code: "",
} as const;

const CurrencyForm: React.FC<UpsertForm<Currency>> = (props) => {
  const { create, update } = useUpsert(
    "currencies",
    CurrencyService.create,
    CurrencyService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateCurrencySchema : UpdateCurrencySchema}
      initialValues={isNew ? defaultInitialCurrency : props.initialValue}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        if ("id" in values) await update(values);
        else await create(values);

        resetForm();
        props.onClose();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (name: string, e: React.ChangeEvent) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} valutu</DialogTitle>
            <DialogContent>
              <DialogContentText>Unesite naziv i kod valute</DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="name"
                    name="name.hr"
                    label="Naziv"
                    fullWidth
                    value={values.name.hr}
                    helperText={<>{touched.name?.hr ? errors.name?.hr : ""}</>}
                    error={touched.name && Boolean(errors.name?.hr)}
                    onChange={change.bind(null, "name.hr")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="code"
                    name="code"
                    label="Kod"
                    fullWidth
                    value={values.code}
                    onChange={change.bind(null, "price")}
                    helperText={<>{touched.code ? errors.code : ""}</>}
                    error={touched.code && Boolean(errors.code)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button type="submit" onClick={() => handleSubmit()}>
                {actionText}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CurrencyForm;
