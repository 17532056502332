import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Option,
  Translation,
  OptionValue,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (
  pagination: WithPagination<{ categoryNameFilter?: number }>
) => {
  const res = await axios.get<Paginated<Option>>(
    withQueryParams("/api/options", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Option>(`/api/options/${id}`);
  return res.data;
};

interface CreateOptionPayload {
  name: Translation;
  description: Translation;
  optionValues: Omit<OptionValue, "id">[];
}

const create = async (payload: CreateOptionPayload) => {
  const res = await axios.post<Option>("/api/options", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Option) => {
  const res = await axios.patch<Option>(`/api/options/${id}`, payload);
  return res.data;
};

const del = async (payload: Option) => {
  await axios.delete<Option>(`/api/options/${payload.id}`);
  return payload;
};

const OptionService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default OptionService;
