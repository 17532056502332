import { Paper, Typography } from "@mui/material";
import { Language, UpsertAction } from "../shared/types";
import DataTable, { GetColumns } from "../components/DataTable/DataTable";
import { LanguageService } from "../services";
import UpsertLanguageForm from "./UpsertLanguageForm";
import MenuOptions from "../shared/MenuOptions/new";

const getColumns: GetColumns<Language> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-language",
            sourceQueryKey: "languages",
            mutationFn: () => LanguageService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete language");
            },
          }}
          onEdit={() =>
            open(
              <UpsertLanguageForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Kod",
    selector: (row) => row.code,
    width: "200px",
  },
  {
    name: "ENG",
    selector: (row) => row.name.en,
    width: "200px",
  },
  {
    name: "Prijevod",
    selector: (row) => row.name.hr,
    width: "200px",
  },
];

const LanguageContainer = () => {
  return (
    <Paper>
      <DataTable
        title="Jezici"
        columns={getColumns}
        queryOptions={{
          queryKey: "languages",
          queryFn: LanguageService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertLanguageForm
                action={UpsertAction.create}
                onClose={close}
              />
            ),
        }}
        noData={<Typography m={2}>Nema dostupnih jezika</Typography>}
      />
    </Paper>
  );
};

export default LanguageContainer;
