import axios from "axios";
import {
  Translation,
  ApiGetResponse as Paginated,
  WithPagination,
  WithId,
} from "../shared/types";
import { withQueryParams } from "./utils";

type GetTranslationOptions = {};

const getAll = async ({
  ...pagination
}: WithPagination<GetTranslationOptions>) => {
  const res = await axios.get<Paginated<WithId<Translation>>>(
    withQueryParams("/api/translation", pagination)
  );
  return res.data;
};

const create = async (payload: Translation) => {
  const res = await axios.post<WithId<Translation>>(
    "/api/translation",
    payload
  );

  return res.data;
};

const update = async ({ id, ...translation }: WithId<Translation>) => {
  const res = await axios.patch<WithId<Translation>>(
    `/api/translation/${id}`,
    translation
  );

  return res.data;
};

const del = async (id: number) => {
  const res = await axios.delete<WithId<Translation>>(`/api/translation/${id}`);
  return res.data;
};

const TranslationService = {
  getAll,
  create,
  update,
  del,
};

export default TranslationService;
