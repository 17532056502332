import { Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { Loader, MenuOptions } from "../../../../shared";
import { Ingredient } from "../../../../shared/types";

interface IngredientsTableProps {
  ingredients: Ingredient[];
  isLoading: boolean;
  handleDelete: (
    ingredientIds: number,
    ingredientOrOption: "option" | "ingredient"
  ) => void;
  // productId: number;
}

const IngredientsTable = ({
  ingredients,
  isLoading,
  handleDelete,
}: // productId,
IngredientsTableProps) => {
  const columns = () => [
    {
      name: "Šifra",
      selector: (row: Ingredient) => row.id,
      width: "100px",
    },
    {
      cell: (row: Ingredient) => {
        return (
          <MenuOptions
            id={row.id}
            onDelete={() => handleDelete(row.id, "ingredient")}
            // onEdit={handleEdit}
          />
        );
      },
      allowOverflow: true,
      button: true,
      width: "56px", // custom width for icon button
    },
    {
      name: "Naziv",
      selector: (row: Ingredient) => row.name.hr,
    },
    {
      name: "Opis",
      selector: (row: Ingredient) => row.description.hr,
    },
  ];

  return (
    <DataTable
      columns={columns()}
      data={ingredients}
      // pagination
      // paginationServer
      // paginationPerPage={rowsPerPage}
      // onChangePage={(page) => setCurrentPage(page)}
      // onChangeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
      // paginationTotalRows={totalRows}
      progressComponent={<Loader />}
      progressPending={isLoading}
      noDataComponent={<Typography m={2}>Nema dostupnih sastojaka</Typography>}
    />
  );
};

export default IngredientsTable;
