import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Currency,
  WithoutId,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (
  pagination: WithPagination<{ categoryNameFilter?: number }>
) => {
  const res = await axios.get<Paginated<Currency>>(
    withQueryParams("/api/currencies", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Currency>(`/api/currencies/${id}`);
  return res.data;
};

const create = async (payload: WithoutId<Currency>) => {
  const res = await axios.post<Currency>("/api/currencies", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Currency) => {
  const res = await axios.patch<Currency>(`/api/currencies/${id}`, payload);
  return res.data;
};

const del = async (payload: Currency) => {
  await axios.delete<Currency>(`/api/currencies/${payload.id}`);
  return payload;
};

const CurrencyService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default CurrencyService;
