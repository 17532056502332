import { useState, useEffect } from "react";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Option, UpsertAction, UpsertForm } from "../../shared/types";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { nanoid } from "nanoid";
import { useUpsert } from "../../hooks";
import { OptionService } from "../../services";

const CreateOptionPayload = {
  name: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
  description: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
};

const CreateOptionSchema = Yup.object().shape(CreateOptionPayload);
const UpdateOptionSchema = Yup.object().shape({
  ...CreateOptionPayload,
  id: Yup.string().required(),
});

const defaultInitialOption = {
  name: "",
  description: "",
  optionValues: [] as OptionValue[],
};

const optionValue = {
  name: "",
  error: false,
};

type OptionValue = {
  id: string;
  name: string;
  error: boolean;
  quantity?: null | number;
};

const UpsertOptionForm: React.FC<UpsertForm<Option>> = (props) => {
  const { create, update } = useUpsert(
    "options",
    OptionService.create,
    OptionService.update
  );
  const [optionValues, setOptionValues] = useState<OptionValue[]>([
    { id: nanoid(), ...optionValue },
  ]);
  const [defaultOption, setDefaultOption] = useState<string | null>(null);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionValues(
      optionValues.map((value, i) => {
        if (i === parseInt(e.target.name)) {
          const optionValue = { ...value };

          if (!e.target.checked) {
            delete optionValue.quantity;
          } else {
            optionValue.quantity = null;
          }

          return optionValue;
        } else {
          return value;
        }
      })
    );
  };

  const addOptionValue = () => {
    const length = optionValues.length - 1;

    if (optionValues[length].name.length)
      setOptionValues([...optionValues, { ...optionValue, id: nanoid() }]);
    else
      setOptionValues([
        ...optionValues.slice(0, length),
        { ...optionValues[length], error: true },
      ]);
  };

  const deleteOptionValue = (id: string) => {
    setOptionValues(
      [...optionValues].filter((optionValue) => optionValue.id !== id)
    );
  };

  const handleOptionValueChange = (
    i: number,
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setOptionValues([
      ...optionValues.slice(0, i),
      { ...optionValues[i], [e.target.name]: e.target.value, error: false },
      ...optionValues.slice(i + 1),
    ]);
  };

  useEffect(() => {
    props.initialValue &&
      setOptionValues(
        props.initialValue.optionValues.map((optionValue) => ({
          id: optionValue.id + "",
          name: optionValue.value.hr,
          quantity: 0,
          error: false,
        }))
      );
  }, [props]);

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateOptionSchema : UpdateOptionSchema}
      initialValues={isNew ? defaultInitialOption : props.initialValue}
      enableReinitialize={true}
      onSubmit={async (payload, { resetForm }) => {
        if ("id" in payload) {
          await update({
            ...payload,
            optionValues: optionValues.map(({ id, name, quantity }) => ({
              id: parseFloat(id),
              value: { hr: name, en: "" },
              quantity: quantity || 1,
              isDefault: id === defaultOption,
            })),
          });
        } else {
          await create({
            ...payload,
            name: {
              hr: payload.name,
              en: "",
            },
            description: {
              hr: payload.description,
              en: "",
            },
            optionValues: optionValues.map(({ id, name, quantity }) => ({
              value: { hr: name, en: "" },
              quantity: quantity || 1,
              isDefault: id === defaultOption,
            })),
          });
        }

        resetForm();
        props.onClose();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (name: string, e: React.ChangeEvent) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose} maxWidth="sm">
            <DialogTitle>{actionText} novu opciju</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    value={values.name}
                    helperText={<>{touched.name ? errors.name : ""}</>}
                    error={touched.name && Boolean(errors.name)}
                    onChange={(e) => change("name", e)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    multiline
                    rows={2}
                    maxRows={2}
                    id="description"
                    name="description"
                    label="Opis"
                    fullWidth
                    value={values.description}
                    onChange={(e) => change("description", e)}
                    helperText={
                      <>{touched.description ? errors.description : ""}</>
                    }
                    error={touched.description && Boolean(errors.description)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <DialogContentText>
                        Dodajte moguće vrijednosti za opciju
                      </DialogContentText>
                    </Grid>
                    <Grid item>
                      <DialogContentText>default</DialogContentText>
                    </Grid>
                    <Grid item>
                      <AddCircleIcon
                        sx={{ mr: 4, mt: 1 }}
                        onClick={addOptionValue}
                      />
                    </Grid>
                  </Grid>
                  <List>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      onChange={(e) => setDefaultOption(e.target.value)}
                    >
                      {optionValues.map((optionValue, i) => (
                        <ListItem disablePadding sx={{ marginBottom: "1rem" }}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4} sm={4}>
                              <TextField
                                name="name"
                                label="Naziv vrijednosti"
                                fullWidth
                                value={optionValue.name}
                                helperText={optionValue.error && "Obavezno"}
                                error={optionValue.error}
                                onChange={(e) => handleOptionValueChange(i, e)}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={optionValue.quantity !== undefined ? 1 : 4}
                              sm={optionValue.quantity !== undefined ? 1 : 4}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={`${i}`}
                                    defaultChecked={
                                      (optionValue.quantity &&
                                        optionValue.quantity >= 0) ||
                                      false
                                    }
                                    onChange={handleCheckboxChange}
                                  />
                                }
                                label={
                                  optionValue.quantity === undefined
                                    ? "Količina"
                                    : ""
                                }
                              />
                            </Grid>

                            {optionValue.quantity !== undefined && (
                              <Grid item xs={3} sm={3}>
                                <TextField
                                  name="quantity"
                                  label="Količina"
                                  type="number"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  fullWidth
                                  value={optionValue.quantity}
                                  onChange={(e) =>
                                    handleOptionValueChange(i, e)
                                  }
                                />
                              </Grid>
                            )}

                            <Grid item sm={2}>
                              <FormControlLabel
                                value={optionValue.id}
                                control={<Radio />}
                                label={null}
                                // label='Female'
                              />
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="center"
                              xs={2}
                              sm={2}
                            >
                              <DeleteForeverIcon
                                onClick={() =>
                                  deleteOptionValue(optionValue.id)
                                }
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </RadioGroup>
                  </List>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button onClick={() => handleSubmit()}>{actionText}</Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertOptionForm;
