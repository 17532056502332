import React, { useEffect, useReducer } from "react";
import Action from "../action-types";
import AppContext from "../context";
import reducer, { initializer } from "../reducer";

type IAppProvider = {
  children: React.ReactElement | null;
};

const userLSKey = "token";

const InitialState = {
  user: null,
};

const AppProvider = ({ children }: IAppProvider) => {
  const [{ user }, dispatch] = useReducer(
    reducer,
    InitialState,
    initializer(userLSKey)
  );

  useEffect(() => {
    const token = localStorage.getItem(userLSKey);
    if (token) {
      setUser(token);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(userLSKey, user);
  }, [user]);

  const setUser = (user: any) => {
    dispatch({ type: Action.SetUser, payload: user });
  };

  return (
    <AppContext.Provider
      value={{
        state: { user },
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
