import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MenuOptions from "../../shared/MenuOptions/new";
import { Product, UpsertAction } from "../../shared/types";
import DataTable, { GetColumns } from "../../components/DataTable/DataTable";
import { ProductService } from "../../services";
import UpsertProductForm from "./UpsertProductForm";

const getColumns: GetColumns<Product> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-product",
            sourceQueryKey: "products",
            mutationFn: () => ProductService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete product");
            },
          }}
          onEdit={() =>
            open(
              <UpsertProductForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Naziv",
    selector: (row) => row.name.hr,
  },
  {
    name: "Cijena",
    selector: (row) => row.price,
    width: "100px",
  },
  {
    name: "Kategorije",
    selector: (row) => row.categories.map((pc) => pc.name.hr).join(", "),
  },
];

const ProductContainer = () => {
  // const handleDetails = (id: number) => {
  //   navigate(`/products/${id}`);
  // };

  return (
    <Paper>
      <DataTable
        title="Proizvodi"
        columns={getColumns}
        queryOptions={{
          queryKey: "products",
          queryFn: ProductService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) => {
            open(
              <UpsertProductForm action={UpsertAction.create} onClose={close} />
            );
          },
        }}
        noData={<Typography m={2}>Nema dostupnih proizvoda</Typography>}
      />
    </Paper>
  );
};

export default ProductContainer;
