import { ContainerPaginationProvider } from "../../contexts/ContainerPaginationContext";
import { UpsertFormProvider } from "../../contexts/UpsertFormContext";
import { PaginationInput } from "../../shared/types";

interface ContainerContextWrapperProps {
  children: React.ReactNode;
  options?: {
    initialPaginationConfig?: PaginationInput;
  };
}

const ContainerContextWrapper: React.FC<ContainerContextWrapperProps> = ({
  children,
  options,
}) => {
  return (
    <ContainerPaginationProvider
      initialPaginationConfig={options?.initialPaginationConfig}
    >
      <UpsertFormProvider>{children}</UpsertFormProvider>
    </ContainerPaginationProvider>
  );
};

export default ContainerContextWrapper;
