import {
  TextField,
  CircularProgress,
  Autocomplete,
  type AutocompleteProps,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

type OptionalBoolean = boolean | undefined;
interface DropdownProps<
  T,
  Multiple extends OptionalBoolean,
  DisableClearable extends OptionalBoolean,
  FreeSolo extends OptionalBoolean
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options" | "getOptionLabel"
  > {
  dropdownFor: string;
  inputLabel: string;
  error?: string | React.ReactNode;
  getOptionLabel: (option: T) => string;
}

const Dropdown = <
  T,
  Multiple extends OptionalBoolean,
  DisableClearable extends OptionalBoolean,
  FreeSolo extends OptionalBoolean
>({
  dropdownFor,
  inputLabel,
  error,
  getOptionLabel,
  ...autocompleteProps
}: DropdownProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState("");

  const loadOptions = useCallback(
    async (filter: string) => {
      try {
        const res = await axios.get(
          `/api/${
            dropdownFor === "category" ? "categorie" : dropdownFor
          }s?${dropdownFor}NameFilter=${filter}`
        );

        setOptions(res.data.results);
        setLoading(false);
      } catch (ex) {
        setOptions([]);
        setLoading(false);
      }
    },
    [dropdownFor]
  );

  useEffect(() => {
    setLoading(true);

    loadOptions(filter);
  }, [filter, loadOptions]);

  return (
    <Autocomplete
      {...autocompleteProps}
      getOptionLabel={(option) => getOptionLabel(option as T)}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label={inputLabel}
          error={!!error}
          helperText={error}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default Dropdown;
