import { Formik } from "formik";
import * as Yup from "yup";
import { Translation, UpsertAction, UpsertForm, WithId } from "../shared/types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import TranslationService from "../services/TranslationService";
import { useUpsert } from "../hooks";

const CreateTranslationPayload = Yup.object().shape({
  hr: Yup.string().required(),
  en: Yup.string().required(),
});

const UpdateTranslationPayload = CreateTranslationPayload.shape({
  id: Yup.number().required(),
  hr: Yup.string().optional(),
  en: Yup.string().optional(),
});

const UpsertTranslationForm: React.FC<UpsertForm<WithId<Translation>>> = (
  props
) => {
  const { create, update } = useUpsert(
    "translations",
    TranslationService.create,
    TranslationService.update
  );
  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={
        isNew ? CreateTranslationPayload : UpdateTranslationPayload
      }
      initialValues={isNew ? { hr: "", en: "" } : props.initialValue}
      enableReinitialize={true}
      onSubmit={async (payload, { resetForm }) => {
        try {
          const isUpdate = !isNew && "id" in payload;
          await (isUpdate ? update(payload) : create(payload));

          resetForm();
          props.onClose();
        } catch (err) {
          // no need to handle error, it's handled in mutation
        }
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (name: string, e: React.ChangeEvent) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} prijevod</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="hr"
                    name="hr"
                    label="Hrvatski"
                    fullWidth
                    value={values.hr}
                    helperText={<>{touched.hr ? errors.hr : ""}</>}
                    error={touched.hr && Boolean(errors.hr)}
                    onChange={(e) => change("hr", e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="en"
                    name="en"
                    label="Engleski"
                    fullWidth
                    value={values.en}
                    helperText={<>{touched.en ? errors.en : ""}</>}
                    error={touched.en && Boolean(errors.en)}
                    onChange={(e) => change("en", e)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button type="submit" onClick={() => handleSubmit()}>
                {actionText}
                {/** TODO: add loading spinner if isCreating or isUpdating */}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertTranslationForm;
