export const withQueryParams = (
  url: string,
  params: Record<string, string | number | number[]>
) => {
  const queryParams = new URLSearchParams();
  Object.keys(params).forEach((key) =>
    queryParams.append(key, `${params[key]}`)
  );

  return `${url}?${queryParams.toString()}`;
};
