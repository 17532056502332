import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Ingredient,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (
  pagination: WithPagination<{ categoryNameFilter?: number }>
) => {
  const res = await axios.get<Paginated<Ingredient>>(
    withQueryParams("/api/ingredients", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Ingredient>(`/api/ingredients/${id}`);
  return res.data;
};

const create = async (payload: Omit<Ingredient, "id">) => {
  const res = await axios.post<Ingredient>("/api/ingredients", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Ingredient) => {
  const res = await axios.patch<Ingredient>(`/api/ingredients/${id}`, payload);
  return res.data;
};

const del = async (payload: Ingredient) => {
  await axios.delete<Ingredient>(`/api/ingredients/${payload.id}`);
  return payload;
};

const ProductCategoryService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default ProductCategoryService;
