import { useContext } from "react";
import { UpsertFormContext } from "../contexts/UpsertFormContext";

export type UseUpsertFormReturn = {
  open: (id: React.ReactNode) => void;
  close: () => void;
};

const useUpsertForm = (): UseUpsertFormReturn => {
  const { setForm } = useContext(UpsertFormContext);

  return {
    open: setForm,
    close: () => setForm(null),
  };
};

export default useUpsertForm;
