import {
  Button,
  Drawer,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  Formik,
  useField,
} from "formik";
import * as Yup from "yup";
import { Dropdown } from "../../shared";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Product,
  Table,
  Waiter,
  Order,
  UpsertForm,
  UpsertAction,
  ProductOption,
} from "../../shared/types";
import { ChangeEvent } from "react";
import { OrderService } from "../../services";
import { useUpsert } from "../../hooks";

const CreateOrderPayload = {
  note: Yup.string().max(100, "Preduga napomena!"),
  table: Yup.object().typeError("Obavezno polje").required("Obavezno polje"),
  waiter: Yup.object().typeError("Obavezno polje").required("Obavezno polje"),
  orderItems: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number().required("Obavezno polje"),
      // note: Yup.string().max(100, "Preduga napomena!"),
      product: Yup.object().required("Obavezno polje"),
      // excludedIngredientIds: Yup.array().of(Yup.number()),
      productOptions: Yup.array().of(
        Yup.object({
          optionId: Yup.number().required("Obavezno polje"),
          optionValueId: Yup.number().required("Obavezno polje"),
        })
      ),
    })
  ),
};

const CreateOrderSchema = Yup.object().shape(CreateOrderPayload);
const UpdateOrderSchema = Yup.object().shape({
  id: Yup.number().required(),
  status: Yup.number().required(),
  ...CreateOrderPayload,
});

type OrderItem = {
  quantity: number;
  product: Product;
  productOptions: ProductOption[];
  note: string;
  excludedIngredientIds: number[];
};

type FormValues =
  | Order
  | {
      note: string;
      table: Table;
      waiter: Waiter;
      orderItems: OrderItem[];
    };

interface ProductDetailProps {
  arrHelpers: FieldArrayRenderProps;
  i: number;
  changeSelect: (
    name: string
  ) => <T>(e: React.SyntheticEvent<Element>, option: T) => void;
  handleChange: (e: ChangeEvent) => void;
  values: FormValues;
}

const ProductDetail = ({
  arrHelpers,
  i,
  changeSelect,
  handleChange,
  values,
}: ProductDetailProps) => {
  const productField = useField({
    name: `orderItems.${i}.product`,
  });
  const optionsField = useField({
    name: `orderItems.${i}.productOptions`,
  });

  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={4} sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Dropdown
                dropdownFor="product"
                getOptionLabel={(option) => option.name.hr}
                inputLabel="Proizvod"
                onChange={changeSelect(`orderItems.${i}.product`)}
                value={values.orderItems[i].product}
                multiple={false}
                error={
                  productField[1].touched && productField[1].error ? (
                    <ErrorMessage name={`orderItems.${i}.product`} />
                  ) : undefined
                }
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                name={`orderItems.${i}.quantity`}
                label="Količina"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                fullWidth
                value={values.orderItems[i].quantity}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <Dropdown
                dropdownFor="option"
                getOptionLabel={(option) => option.option.name.hr}
                inputLabel="Opcije"
                onChange={changeSelect(`orderItems.${i}.productOptions`)}
                value={values.orderItems[i].productOptions}
                error={
                  optionsField[1].touched && optionsField[1].error ? (
                    <ErrorMessage name={`orderItems.${i}.productOptions`} />
                  ) : undefined
                }
              />
            </Grid>

            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                color="error"
                sx={{ width: "100%" }}
                onClick={() => arrHelpers.remove(i)}
              >
                <DeleteForeverIcon fontSize="large" />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

const defaultInitialOrder = {
  note: "",
  table: null as any as Table,
  waiter: null as any as Waiter,
  orderItems: [] as OrderItem[],
};

const OrderForm: React.FC<UpsertForm<Order>> = (props) => {
  const { create, update } = useUpsert(
    "orders",
    OrderService.create,
    OrderService.update
  );

  const isNew = props.action === UpsertAction.create;
  // const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Drawer
      anchor="right"
      open
      onClose={props.onClose}
      PaperProps={{
        sx: {
          width: "35rem",
          p: 2,
        },
      }}
    >
      <Formik
        validationSchema={isNew ? CreateOrderSchema : UpdateOrderSchema}
        initialValues={isNew ? defaultInitialOrder : props.initialValue}
        enableReinitialize
        onSubmit={async (values, { resetForm }) => {
          const isUpdate = "id" in values;

          if (isUpdate) await update(values);
          else {
            await create({
              ...values,
              tableId: values.table.id,
              waiterId: values.waiter.id,
              orderItems: values.orderItems.map((oi) => ({
                productId: oi.product.id,
                quantity: oi.quantity,
                note: oi.note,
                excludedIngredientIds: oi.excludedIngredientIds,
                selectedOptions: oi.productOptions.map((po) => ({
                  optionId: po.option.id,
                  optionValueId:
                    po.option.optionValues.find((ov) => ov.isDefault)?.id ||
                    po.option.optionValues[0].id,
                })),
              })),
            });
          }

          resetForm();
        }}
      >
        {({
          isValid,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
        }) => {
          const changeSelect =
            (name: string) =>
            <T,>(e: React.SyntheticEvent<Element>, option: T) => {
              console.log("why");
              setFieldValue(name, option);
              setFieldTouched(name, true, false);
            };

          return (
            <>
              <Typography variant="h5" sx={{ textAlign: "center" }} pb={2}>
                Nova Narudžba
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Dropdown
                    dropdownFor="table"
                    getOptionLabel={(option) => option.name}
                    inputLabel="Stol"
                    onChange={changeSelect("table")}
                    value={values.table}
                    multiple={false}
                    error={
                      touched.table && errors.table ? (
                        <>{errors.table}</>
                      ) : undefined
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    dropdownFor="waiter"
                    getOptionLabel={(option) =>
                      `${option.name} ${option.surname}`
                    }
                    inputLabel="Konobar"
                    onChange={changeSelect("waiter")}
                    value={values.waiter}
                    multiple={false}
                    error={
                      touched.waiter && errors.waiter ? (
                        <>{errors.waiter}</>
                      ) : undefined
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="note"
                    name="note"
                    label="Napomena"
                    fullWidth
                    multiline
                    rows={3}
                    value={values.note}
                    helperText={touched.note ? errors.note : ""}
                    error={touched.note && Boolean(errors.note)}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Typography p={2}>Products</Typography>
              <Grid container spacing={2}>
                <FieldArray
                  name="orderItems"
                  render={(arrHelpers) => (
                    <>
                      {values.orderItems.map((item, i) => (
                        <ProductDetail
                          arrHelpers={arrHelpers}
                          i={i}
                          changeSelect={changeSelect}
                          handleChange={handleChange}
                          values={values}
                        />
                      ))}
                      <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                        <Button
                          variant="outlined"
                          disabled={!!errors.orderItems}
                          onClick={() => arrHelpers.push({})}
                        >
                          Dodaj proizvod
                        </Button>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="flex-end"
                sx={{ height: "100%" }}
              >
                <Grid item xs={6}>
                  <Button variant="text" onClick={props.onClose}>
                    odustani
                  </Button>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    onClick={(e) => handleSubmit()}
                    disabled={!isValid}
                  >
                    Stvori narudžbu
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </Drawer>
  );
};

export default OrderForm;
