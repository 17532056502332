import { QueryClient, QueryKey } from "react-query";
import { PaginationInput, WithId } from "../shared/types";
import { ApiGetResponse as Paginated } from "../shared/types";

type MutationHandler = <T, U extends WithId<T>>(
  item: U,
  sourceQueryKey: QueryKey,
  client: QueryClient,
  pagination: PaginationInput
) => void;

type CreateMutationHandler = <T>(
  item: T,
  sourceQueryKey: QueryKey,
  client: QueryClient,
  pagination: PaginationInput
) => void;

const getDefaultResponse = <T>(items: T[], pagination: PaginationInput) => ({
  currentPage: pagination.page,
  pageCount: 1,
  pageSize: pagination.pageSize,
  rowCount: items.length,
  firstRowOnPage: 1,
  lastRowOnPage: items.length,
  results: items,
});

export const handleDeleteMutationSuccess: MutationHandler = (
  item,
  sourceQueryKey,
  client,
  pagination
) => {
  const { page, pageSize } = pagination;

  client.setQueryData(
    [sourceQueryKey, page, pageSize],
    (old?: Paginated<typeof item>) => {
      if (!old) return getDefaultResponse([], pagination);

      const items = [...old.results];
      return {
        ...old,
        results: items.filter((i) => i.id !== item.id),
      };
    }
  );
};

export const handleCreateMutationSuccess: CreateMutationHandler = (
  item,
  sourceQueryKey,
  client,
  pagination
) => {
  const { page, pageSize } = pagination;

  client.setQueryData(
    [sourceQueryKey, page, pageSize],
    (old?: Paginated<typeof item>) => {
      if (!old) return getDefaultResponse([item], pagination);

      if (old.results.length < old.pageSize) {
        return { ...old, results: [item, ...old.results] };
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...items] = old.results;
      return { ...old, results: [item, ...items] };
    }
  );
};

export const handleUpdateMutationSuccess: MutationHandler = (
  item,
  sourceQueryKey,
  client,
  pagination
) => {
  const { page, pageSize } = pagination;

  client.setQueryData(
    [sourceQueryKey, page, pageSize],
    (old?: Paginated<typeof item>) => {
      if (!old) return getDefaultResponse([item], pagination);

      const items = [...old.results];
      const index = items.findIndex((i) => i.id === item.id);
      items[index] = item;

      return { ...old, results: items };
    }
  );
};
