import { createContext, useState } from "react";
import { PaginationInput } from "../shared/types";

type IContainerPaginationContext = {
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
};

export const ContainerPaginationContext = createContext(
  {} as IContainerPaginationContext
);

type ContainerPaginationProviderProps = {
  children: React.ReactNode;
  initialPaginationConfig?: PaginationInput;
};

export const ContainerPaginationProvider: React.FC<
  ContainerPaginationProviderProps
> = ({ children, initialPaginationConfig }) => {
  const [page, setPage] = useState(initialPaginationConfig?.page || 1);
  const [pageSize, setPageSize] = useState(
    initialPaginationConfig?.pageSize || 10
  );

  return (
    <ContainerPaginationContext.Provider
      value={{
        page,
        pageSize,
        setPage,
        setPageSize,
      }}
    >
      {children}
    </ContainerPaginationContext.Provider>
  );
};
