import {
  useState,
  useMemo,
  useEffect,
  useLayoutEffect,
  forwardRef,
} from "react";
import { Route, Routes, Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import clsx from "clsx";

import Home from "./dashboard/DashboardContainer";
import CategoryContainer from "./category/CategoryContainer/CategoryContainer";
import OrderContainer from "./orders/OrderContainer/OrderContainer";

import OptionContainer from "./options/OptionContainer/OptionContainer";
import IngredientContainer from "./ingredients/IngredientContainer/IngredientContainer";
import WaiterContainer from "./waiters/WaiterContainer/WaiterContainer";
import ProductContainer from "./product/ProductContainer/ProductContainer";
import ProductDetailContainer from "./product/ProductDetailContainer/ProductDetailContainer";
import TableContainer from "./table/TableContainer/TableContainer";
import TableDetailsContainer from "./table/TableDetailsContainer";
import TaxesContainer from "./taxes/TaxesContainer";
import LanguageContainer from "./language/LanguageContainer";
import CurrencyContainer from "./currency/CurrencyContainer";

import { makeStyles, useTheme } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Icon, ListItemButton, Theme } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PercentIcon from "@mui/icons-material/Percent";
import LanguageIcon from "@mui/icons-material/Language";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ApiAuthorzationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TuneIcon from "@mui/icons-material/Tune";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CategoryIcon from "@mui/icons-material/Category";
import TableBarIcon from "@mui/icons-material/TableBar";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import LogoPath from "./assets/image/logo/svg/horizontal_light.svg";
import CollapsedLogoPath from "./assets/image/logo/svg/logo_light.svg";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import TranslationsContainer from "./translations/TranslationsContainer";
import { ContainerContextWrapper } from "./components";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

type ListItemLinkProps = {
  icon: React.ReactNode;
  primary: string;
  to: string;
  isActive: boolean;
};

const ListItemLink = ({ icon, primary, to, isActive }: ListItemLinkProps) => {
  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement>((itemProps, ref) => {
        return (
          // with react-router-dom@^5.0.0 use `ref` instead of `innerRef`
          <RouterLink to={to} {...itemProps} ref={ref} />
        );
      }),
    [to]
  );

  return (
    <li>
      <ListItemButton selected={isActive} component={renderLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
};

const App = () => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const location = useLocation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isProductListOpen, setProductListOpen] = useState(true);
  const [isVenueListOpen, setVenueListOpen] = useState(true);
  const [isSettingsOpen, setSettingsOpen] = useState(true);
  const [isAdminOpen, setAdminOpen] = useState(true);
  const [isAnalyticsListOpen, setAnalyticsListOpen] = useState(true);
  const [isSynced, setIsSynced] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const calcMargin = () => (isDrawerOpen ? 2 : 1);

  useLayoutEffect(() => {
    const drawerLists = JSON.parse(`${localStorage.getItem("drawerLists")}`);

    if (drawerLists) {
      const { product, venu, settings, admin, analytics } = drawerLists;

      setProductListOpen(product);
      setVenueListOpen(venu);
      setSettingsOpen(settings);
      setAdminOpen(admin);
      setAnalyticsListOpen(analytics);
    }

    setIsSynced(true);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "drawerLists",
      JSON.stringify({
        product: isProductListOpen,
        venu: isVenueListOpen,
        settings: isSettingsOpen,
        admin: isAdminOpen,
        analytics: isAnalyticsListOpen,
      })
    );
  }, [
    isProductListOpen,
    isVenueListOpen,
    isSettingsOpen,
    isAdminOpen,
    isAnalyticsListOpen,
  ]);

  return isSynced ? (
    <div className={classes.root}>
      <CssBaseline />
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        // container={props.container}
        variant="permanent"
        anchor={theme.direction === "rtl" ? "right" : "left"}
        onClose={toggleDrawer}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          }),
        }}
        ModalProps={{
          keepMounted: true, // Better isDrawerOpen performance on mobile.
        }}
      >
        <div
          style={{ height: "100%", flexDirection: "column", display: "flex" }}
        >
          <List component="div" disablePadding>
            <ListItemIcon sx={{ width: "100%" }}>
              <Icon
                sx={{ width: "100%", height: "100%", padding: "10px 5px 0" }}
              >
                <img
                  alt="Logo"
                  src={isDrawerOpen ? LogoPath : CollapsedLogoPath}
                  width={"100%"}
                  height={"100%"}
                />
              </Icon>
            </ListItemIcon>
            <List
              component="div"
              disablePadding
              subheader={
                <ListItemButton
                  onClick={() => setAnalyticsListOpen(!isAnalyticsListOpen)}
                >
                  <ListItemIcon>
                    <InsertChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Analitika" />
                  {isAnalyticsListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              }
            >
              <Collapse in={isAnalyticsListOpen} timeout="auto">
                <ListItemLink
                  to="/"
                  icon={<DashboardIcon sx={{ ml: calcMargin() }} />}
                  primary={"Nadzorna ploča"}
                  isActive={location.pathname === "/"}
                />
                <ListItemLink
                  to="/orders"
                  icon={<LocalShippingIcon sx={{ ml: calcMargin() }} />}
                  primary={"Narudžbe"}
                  isActive={location.pathname === "/orders"}
                />
              </Collapse>
            </List>
            <List
              component="div"
              disablePadding
              subheader={
                <ListItemButton
                  onClick={() => setProductListOpen(!isProductListOpen)}
                >
                  <ListItemIcon>
                    <Inventory2Icon />
                  </ListItemIcon>
                  <ListItemText primary="Katalog" />
                  {isProductListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              }
            >
              <Collapse in={isProductListOpen} timeout="auto">
                <ListItemLink
                  to="/products"
                  icon={<FastfoodIcon sx={{ ml: calcMargin() }} />}
                  primary={"Proizvodi"}
                  isActive={location.pathname === "/products"}
                />
                <ListItemLink
                  to="/categories"
                  icon={<CategoryIcon sx={{ ml: calcMargin() }} />}
                  primary={"Kategorije"}
                  isActive={location.pathname === "/categories"}
                />
                <ListItemLink
                  to="/ingredients"
                  icon={<AddBoxIcon sx={{ ml: calcMargin() }} />}
                  primary={"Sastojci"}
                  isActive={location.pathname === "/ingredients"}
                />
                <ListItemLink
                  to="/options"
                  icon={<TuneIcon sx={{ ml: calcMargin() }} />}
                  primary={"Opcije"}
                  isActive={location.pathname === "/options"}
                />
              </Collapse>
            </List>
            <List
              component="div"
              disablePadding
              subheader={
                <ListItemButton
                  onClick={() => setVenueListOpen(!isVenueListOpen)}
                >
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText primary="Lokal" />
                  {isVenueListOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              }
            >
              <Collapse in={isVenueListOpen} timeout="auto">
                <ListItemLink
                  to="/waiters"
                  icon={<PersonIcon sx={{ ml: calcMargin() }} />}
                  primary={"Konobari"}
                  isActive={location.pathname === "/waiters"}
                />
                <ListItemLink
                  to="/tables"
                  icon={<TableBarIcon sx={{ ml: calcMargin() }} />}
                  primary={"Stolovi"}
                  isActive={location.pathname === "/tables"}
                />
              </Collapse>
            </List>
          </List>
          <div style={{ flex: 1 }} />
          <List
            component="div"
            disablePadding
            subheader={
              <ListItemButton onClick={() => setSettingsOpen(!isSettingsOpen)}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Postavke" />
                {isSettingsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            }
          >
            <Collapse in={isSettingsOpen} timeout="auto">
              <ListItemLink
                to="/taxes"
                icon={<PercentIcon sx={{ ml: calcMargin() }} />}
                primary={"Porezi"}
                isActive={location.pathname === "/taxes"}
              />
              <ListItemLink
                to="/languages"
                icon={<LanguageIcon sx={{ ml: calcMargin() }} />}
                primary={"Jezici"}
                isActive={location.pathname === "/languages"}
              />
              <ListItemLink
                to="/currencies"
                icon={<CurrencyExchangeIcon sx={{ ml: calcMargin() }} />}
                primary={"Valute"}
                isActive={location.pathname === "/currencies"}
              />
              <ListItemLink
                to="/translations"
                icon={<CurrencyExchangeIcon sx={{ ml: calcMargin() }} />}
                primary={"Prijevodi"}
                isActive={location.pathname === "/translations"}
              />
            </Collapse>
          </List>
          <List
            component="div"
            disablePadding
            subheader={
              <ListItemButton onClick={() => setAdminOpen(!isAdminOpen)}>
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {isAdminOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            }
          >
            <Collapse in={isAdminOpen} timeout="auto">
              <ListItemLink
                to="/users"
                icon={<PeopleIcon sx={{ ml: calcMargin() }} />}
                primary={"Korisnici"}
                isActive={location.pathname === "/users"}
              />
              <ListItemLink
                to="/settings"
                icon={<SettingsApplicationsIcon sx={{ ml: calcMargin() }} />}
                primary={"Postavke"}
                isActive={location.pathname === "/settings"}
              />
            </Collapse>
          </List>
          <List>
            <ListItemButton onClick={toggleDrawer}>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={"Collapse"} />
            </ListItemButton>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Container>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route
              path="/categories/"
              element={
                <ContainerContextWrapper>
                  <CategoryContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/waiters/"
              element={
                <ContainerContextWrapper>
                  <WaiterContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/options/"
              element={
                <ContainerContextWrapper>
                  <OptionContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/orders/"
              element={
                <ContainerContextWrapper>
                  <OrderContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/ingredients/"
              element={
                <ContainerContextWrapper>
                  <IngredientContainer />
                </ContainerContextWrapper>
              }
            />

            <Route
              path="/products/"
              element={
                <ContainerContextWrapper>
                  <ProductContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/products/:productId"
              element={<ProductDetailContainer />}
            />
            <Route
              path="/tables/"
              element={
                <ContainerContextWrapper>
                  <TableContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/tables/:tableId"
              element={<TableDetailsContainer />}
            />

            <Route
              path="/taxes"
              element={
                <ContainerContextWrapper>
                  <TaxesContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/languages"
              element={
                <ContainerContextWrapper>
                  <LanguageContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/currencies"
              element={
                <ContainerContextWrapper>
                  <CurrencyContainer />
                </ContainerContextWrapper>
              }
            />
            <Route
              path="/translations"
              element={
                <ContainerContextWrapper>
                  <TranslationsContainer />
                </ContainerContextWrapper>
              }
            />
            {ApiAuthorzationRoutes.map((route, index) => {
              const { element, requireAuth, ...rest } = route;
              return (
                <Route
                  key={index}
                  {...rest}
                  element={
                    requireAuth ? (
                      <AuthorizeRoute {...rest} element={element} />
                    ) : (
                      element
                    )
                  }
                />
              );
            })}
          </Routes>
          {/* <Route path='/users/' component={UserContainer} /> */}
        </Container>
      </main>
      <ToastContainer />
    </div>
  ) : null;
};

export default App;
