import { useState, createContext, useEffect } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr"; // @ts-ignore
import bell from "../../assets/audio/ServiceBell.mp3";

interface SignalRProviderProps {
  children: React.ReactNode;
}

export const SignalRContext = createContext<{
  connection: null | HubConnection;
  muteNotifications: boolean;
  setMuteNotifications: (val: boolean) => void;
}>({
  connection: null,
  muteNotifications: false,
  setMuteNotifications: () => {},
});

const SignalRProvider = ({ children }: SignalRProviderProps) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [audio] = useState(new Audio(bell));
  const [muteNotifications, setMuteNotifications] = useState(false);

  useEffect(() => {
    const hubConnection = new HubConnectionBuilder()
      .withUrl("/orderHub", {})
      .configureLogging(LogLevel.Information)
      .build();

    hubConnection
      .start()
      .then(() => console.log("Connection started!"))
      .catch(() => {
        console.log("Error while establishing connection :(");
        // this.setState({ error: JSON.stringify(err) });
      });

    setConnection(hubConnection);
  }, []);

  useEffect(() => {
    const playAudio = () => {
      if (!muteNotifications) {
        audio.currentTime = 0.9;
        audio.play();
      }
    };

    connection?.on("refreshOrders", playAudio);

    return () => {
      connection?.off("refreshOrders", playAudio);
    };
  }, [muteNotifications, audio, connection]);

  return (
    <SignalRContext.Provider
      value={{ connection, muteNotifications, setMuteNotifications }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;
