import { createContext, useState } from "react";

type TUpsertFormContext = {
  setForm: (form: React.ReactNode) => void;
};

export const UpsertFormContext = createContext({} as TUpsertFormContext);

export const UpsertFormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [form, setForm] = useState<React.ReactNode>(null);

  return (
    <UpsertFormContext.Provider
      value={{
        setForm,
      }}
    >
      {children}
      {form}
    </UpsertFormContext.Provider>
  );
};
