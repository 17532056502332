import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Order,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (
  pagination: WithPagination<{
    waiterId?: number;
    tableId?: number;
    statuses?: number[];
  }>
) => {
  const res = await axios.get<Paginated<Order>>(
    withQueryParams("/api/orders", pagination)
  );
  return res.data;
};

type CreateOrderItemPayload = {
  quantity: number;
  note: string;
  productId: number;
  excludedIngredientIds: number[];
  selectedOptions: {
    optionId: number;
    optionValueId: number;
  }[];
};

type CreateOrderPayload = {
  note: string;
  tableId: number;
  waiterId: number;
  orderItems: CreateOrderItemPayload[];
};

const create = async (payload: CreateOrderPayload) => {
  const res = await axios.post<Order>("/api/orders", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Order) => {
  const res = await axios.put<Order>(`/api/orders/${id}`, payload);

  return res.data;
};

const OrderService = {
  getAll,
  create,
  update,
};

export default OrderService;
