import { Typography } from "@mui/material";
import DataTable from "react-data-table-component";
import { Loader, MenuOptions } from "../../../../shared";
import { Option } from "../../../../shared/types";

interface OptionsTableProps {
  options: Option[];
  isLoading: boolean;
  handleDelete: (
    id: number,
    ingredientOrOption: "option" | "ingredient"
  ) => void;
}

const OptionsTable = ({
  options,
  isLoading,
  handleDelete,
}: OptionsTableProps) => {
  const columns = () => [
    {
      name: "Šifra",
      selector: (row: Option) => row.id,
      width: "100px",
    },
    {
      cell: (row: Option) => {
        return (
          <MenuOptions
            id={row.id}
            onDelete={() => handleDelete(row.id, "option")}
            // onEdit={handleEdit}
          />
        );
      },
      allowOverflow: true,
      button: true,
      width: "56px", // custom width for icon button
    },
    {
      name: "Naziv",
      selector: (row: Option) => row.name.hr,
    },
    {
      name: "Opis",
      selector: (row: Option) => row.description.hr,
    },
  ];

  return (
    <DataTable
      columns={columns()}
      data={options}
      // pagination
      // paginationServer
      // paginationPerPage={rowsPerPage}
      // onChangePage={(page) => setCurrentPage(page)}
      // onChangeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
      // paginationTotalRows={totalRows}
      progressComponent={<Loader />}
      progressPending={isLoading}
      noDataComponent={<Typography m={2}>Nema dostupnih opcija</Typography>}
    />
  );
};

export default OptionsTable;
