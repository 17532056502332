import TranslationService from "../services/TranslationService";
import { Paper } from "@mui/material";
import { DataTable } from "../components";
import MenuOptions from "../shared/MenuOptions/new";
import UpsertTranslationForm from "./UpsertTranslationForm";
import { Translation, UpsertAction, WithId } from "../shared/types";
import { GetColumns } from "../components/DataTable/DataTable";

const getColumns: GetColumns<WithId<Translation>> = ({ open, close }) => [
  {
    // TODO: remove WithId where id isn't needed (e.g. in create), add CreatePayload utility type
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-translation",
            sourceQueryKey: "translations",
            mutationFn: () => TranslationService.del(row.id),
            onError: (toast) => {
              toast.error("Failed to delete translation");
            },
          }}
          onEdit={() =>
            open(
              <UpsertTranslationForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px",
  },
  {
    name: "hrvatski",
    selector: (row) => row.hr,
    width: "200px",
  },
  {
    name: "engleski",
    selector: (row) => row.en,
    width: "200px",
  },
];

const TranslationsContainer = () => {
  return (
    <Paper>
      <DataTable
        title="Prijevodi"
        queryOptions={{
          queryKey: "translations",
          queryFn: (pagination) => TranslationService.getAll(pagination),
        }}
        columns={getColumns}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertTranslationForm
                action={UpsertAction.create}
                onClose={close}
              />
            ),
        }}
        noData="Nema dostupnih prijevoda"
      />
    </Paper>
  );
};

export default TranslationsContainer;
