import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Waiter,
  WithoutId,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async ({ ...pagination }: WithPagination<{}>) => {
  const res = await axios.get<Paginated<Waiter>>(
    withQueryParams("/api/waiters", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Waiter>(`/api/waiters/${id}`);
  return res.data;
};

const create = async (payload: WithoutId<Waiter>) => {
  const res = await axios.post<Waiter>("/api/waiters", payload);

  return res.data;
};

const update = async ({ id, ...translation }: Waiter) => {
  const res = await axios.patch<Waiter>(`/api/waiters/${id}`, translation);

  return res.data;
};

const del = async (payload: Waiter) => {
  await axios.delete<Waiter>(`/api/waiters/${payload.id}`);
  return payload;
};

const WaiterService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default WaiterService;
