import * as token from "./token";

export const tokenService = token;
export { default as TranslationService } from "./TranslationService";
export { default as WaiterService } from "./WaiterService";
export { default as TableService } from "./TableService";
export { default as ProductService } from "./ProductService";
export { default as CategoryService } from "./CategoryService";
export { default as IngredientService } from "./IngredientService";
export { default as OptionService } from "./OptionService";
export { default as TaxService } from "./TaxService";
export { default as LanguageService } from "./LanguageService";
export { default as CurrencyService } from "./CurrencyService";
export { default as OrderService } from "./OrderService";
