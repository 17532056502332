import React from "react";
import { makeStyles, createStyles } from "@mui/styles";
import clsx from "clsx";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";

const ProductAttributeSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 4),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

// const loadOptions = (inputValue, callback) => {
//   axios.get("/api/product-categories").then((res) => {
//     callback(
//       res.data.results.map((pc) => ({
//         label: pc.name,
//         value: pc.id,
//       }))
//     );
//   });
// };

const mapPropsToValues = ({ selected }) => {
  if (!selected) {
    return {
      name: "",
    };
  }

  return {
    name: selected.name,
  };
};

function ProductForm(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div style={modalStyle} className={classes.paper}>
      <Formik
        validationSchema={ProductAttributeSchema}
        initialValues={mapPropsToValues(props)}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const payload = { ...values };

          if (payload.productCategoryId) {
            payload.productCategoryId = payload.productCategoryId.value;
          }

          if (!props.selected) {
            props.onCreate(payload);
          } else {
            props.onUpdate({ id: props.selected.id, ...payload });
          }

          resetForm();
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleSubmit,
            handleChange,
            setFieldTouched,
            initialValues,
          } = props;

          if (initialValues !== props.initialValues) return null;

          const change = (name, e) => {
            e.persist();
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          // const changeSelect = (name, option) => {
          //   // handleChange(e.value);
          //   setFieldValue(name, option);
          //   setFieldTouched(name, true, false);
          // };

          return (
            <React.Fragment>
              <Avatar className={classes.avatar}>
                <SaveIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Dodaj proizvod
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    value={values.name}
                    helperText={touched.name ? errors.name : ""}
                    error={touched.name && Boolean(errors.name)}
                    onChange={change.bind(null, "name")}
                  />
                </Grid>
              </Grid>
              <Button
                // variant="primary"
                className={classes.submit}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                <SaveIcon
                  className={clsx(classes.leftIcon, classes.iconSmall)}
                />
                Save
              </Button>
            </React.Fragment>
          );
        }}
      </Formik>
    </div>
  );
}

export default ProductForm;
