import { useContext } from "react";
import { ContainerPaginationContext } from "../contexts/ContainerPaginationContext";

const useContainerPagination = () => {
  const containerPagination = useContext(ContainerPaginationContext);

  return containerPagination;
};

export default useContainerPagination;
