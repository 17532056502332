import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import { Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CategoryIcon from "@mui/icons-material/Category";
import TuneIcon from "@mui/icons-material/Tune";
import ExtensionIcon from "@mui/icons-material/Extension";
import { IngredientsTable, OptionsTable } from "./components";
import { Product } from "../../shared/types";
import { AddIngredientOrOptionForm } from "./components";

type KeyTranslations = { [key: string]: string };
type AvatarIcons = { [key: string]: React.ReactElement };

type OptionOrIngredient = "option" | "ingredient";

const keyTranslations: KeyTranslations = {
  description: "Opis",
  price: "Cijena",
  tax: "Porez",
  categories: "Kategorije",
  ingredients: "Sastojci",
  options: "Opcije",
};

const avatarIcons: AvatarIcons = {
  description: <DescriptionIcon />,
  price: <AttachMoneyIcon />,
  tax: <PercentIcon />,
  categories: <CategoryIcon />,
  ingredients: <TuneIcon />,
  options: <ExtensionIcon />,
};

const ProductDetailContainer = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState<Product | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [, setIsCreating] = useState(false);
  // const [productAttributes, setProductAttributes] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e: React.SyntheticEvent, selectedTab: number) =>
    setCurrentTab(selectedTab);

  const handleCreate = async (
    optionOrIngredient: OptionOrIngredient,
    payload: {
      productId: number;
      ingredientId: number;
      mappings?: {
        optionValueId: number;
        quantity: number;
      }[];
    }
  ) => {
    setIsCreating(true);

    try {
      await axios.post<Product>(`/api/product-${optionOrIngredient}s`, payload);

      fetchData();
      toast.success("Sastojak uspješno dodan!");

      setIsCreating(false);
      setIsFormVisible(false);
    } catch (err) {
      toast.error("Došlo je do pogreške prilikom dodavanja sastojka!");

      setIsCreating(false);
      setIsFormVisible(false);
    }
  };

  const handleDelete = async (
    ingredientIds: number,
    optionOrIngredient: OptionOrIngredient
  ) => {
    try {
      await axios.delete(
        `/api/product-${optionOrIngredient}s/${productId}?ingredientIds=${ingredientIds}`
      );

      fetchData();
      toast.success("Kategorija uspješno izbrisana!");
    } catch (err) {
      toast.error("Došlo je do pogreške prilikom brisanja kategorije !");
      setIsCreating(false);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await axios.get<Product>(`/api/products/${productId}`);
      setProduct(res.data);
      setIsLoading(false);
    } catch (error) {
      toast.error("Došlo je do pogreške prilikom dohvaćanja proizvoda!");
      setIsLoading(false);
    }
  }, [productId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Paper>
      <Typography p={2}>{product?.name.hr}</Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label="Općenito" />
            <Tab label="Sastojci" />
            <Tab label="Opcije" />
            {(currentTab === 1 || currentTab === 2) && (
              <Button key="add" onClick={() => setIsFormVisible(true)}>
                <AddIcon />
                Dodaj
              </Button>
            )}
          </Tabs>
        </Box>
        {currentTab === 0 && (
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Grid container spacing={2}>
              {Object.entries(product || {})
                .filter(([key]) => key !== "id" && key !== "name")
                .map(([key, value]) => (
                  <Grid item md={4}>
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>{avatarIcons[key]}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={keyTranslations[key]}
                        secondary={
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            <>{Array.isArray(value) ? value.length : value}</>
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grid>
                ))}
            </Grid>
          </List>
        )}
        {currentTab === 1 && (
          <IngredientsTable
            ingredients={product?.ingredients || []}
            isLoading={isLoading}
            handleDelete={handleDelete}
          />
        )}
        {currentTab === 2 && (
          <OptionsTable
            options={product?.options || []}
            isLoading={isLoading}
            handleDelete={handleDelete}
          />
        )}
        {product && (
          <AddIngredientOrOptionForm
            open={isFormVisible}
            productId={product.id}
            onClose={() => setIsFormVisible(false)}
            onCreate={handleCreate}
            optionOrIngredient={currentTab === 1 ? "ingredient" : "option"}
          />
        )}
      </Box>
    </Paper>
  );
};

export default ProductDetailContainer;
