import { MutationFunction, useMutation, useQueryClient } from "react-query";
import useContainerPagination from "./useContainerPagination";
import {
  handleCreateMutationSuccess,
  handleUpdateMutationSuccess,
} from "../utils/mutationHandlers";
import { WithId } from "../shared/types";
import { toast } from "react-toastify";

const useUpsert = <A, B>(
  queryKey: string,
  create: MutationFunction<A, B>,
  update: MutationFunction<WithId<A>, A>
) => {
  const client = useQueryClient();
  const { page, pageSize } = useContainerPagination();
  const pagination = { page, pageSize };
  const createMutation = useMutation(`create-${queryKey}`, create, {
    onSuccess: (newWaiter) =>
      handleCreateMutationSuccess(newWaiter, queryKey, client, pagination),
    onError: () => {
      toast(
        "Nismo uspjeli ažurirati vaše podatake. Osvježite stranicu i pokušajte ponovno. Ako se problem nastavi, slobodno se obratite našem timu za podršku.",
        { type: "error" }
      );
    },
  });
  const updateMutation = useMutation(`update-${queryKey}`, update, {
    onSuccess: (updatedWaiter, variables) => {
      // if api returns 204 i.e. no data so just use inputted data
      handleUpdateMutationSuccess(
        updatedWaiter || variables,
        queryKey,
        client,
        pagination
      );
    },
    onError: () => {
      toast(
        "Nismo uspjeli ažurirati vaše podatake. Osvježite stranicu i pokušajte ponovno. Ako se problem nastavi, slobodno se obratite našem timu za podršku",
        { type: "error" }
      );
    },
  });

  return {
    create: createMutation.mutateAsync,
    update: updateMutation.mutateAsync,
  };
};

export default useUpsert;
