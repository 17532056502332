import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Ingredient, UpsertAction, UpsertForm } from "../../shared/types";
import React from "react";
import { IngredientService } from "../../services";
import { useUpsert } from "../../hooks";

const CreateIngredientPayload = {
  name: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
};

const CreateIngredientSchema = Yup.object().shape(CreateIngredientPayload);
const UpdateIngredientSchema = Yup.object().shape({
  ...CreateIngredientPayload,
  id: Yup.number().required(),
});

const defaultInitialIngredient = {
  name: "",
  description: "",
};

const UpsertIngredientForm: React.FC<UpsertForm<Ingredient>> = (props) => {
  const { create, update } = useUpsert(
    "categories",
    IngredientService.create,
    IngredientService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateIngredientSchema : UpdateIngredientSchema}
      initialValues={isNew ? defaultInitialIngredient : props.initialValue}
      enableReinitialize
      onSubmit={(payload, { resetForm }) => {
        if ("id" in payload) update(payload);
        else
          create({
            name: {
              hr: payload.name,
              en: "",
            },
            description: {
              hr: payload.description,
              en: "",
            },
          });

        resetForm();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (
          name: string,
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} sastojak</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Odaberite naziv i moguce vrijednosti za opciju
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    value={values.name}
                    helperText={<>{touched.name ? errors.name : ""}</>}
                    error={touched.name && Boolean(errors.name)}
                    onChange={change.bind(null, "name")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="description"
                    name="description"
                    label="Opis"
                    fullWidth
                    multiline
                    rows={3}
                    value={values.description}
                    onChange={change.bind(null, "description")}
                    helperText={
                      <>{touched.description ? errors.description : ""}</>
                    }
                    error={touched.description && Boolean(errors.description)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Odustani</Button>
              <Button onClick={() => handleSubmit()}>{actionText}</Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertIngredientForm;
