import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Language, UpsertAction, UpsertForm } from "../shared/types";
import { useUpsert } from "../hooks";
import { LanguageService } from "../services";

const schemaError = {
  toShort: "Mora sadržavati barem 2 znaka",
  toLong: "Ne smije sadržavati više od 50 znakova",
};

const CreateLanguagePayload = {
  code: Yup.string().required("Obavezno").length(2, schemaError.toShort),
  name: Yup.object({
    hr: Yup.string()
      .required("Obavezno")
      .min(3, schemaError.toShort)
      .max(50, schemaError.toLong),
    en: Yup.string()
      .required("Obavezno")
      .min(3, schemaError.toShort)
      .max(50, schemaError.toLong),
  }),
};

const CreateLanguageSchema = Yup.object().shape(CreateLanguagePayload);
const UpdateLanguageSchema = Yup.object().shape({
  ...CreateLanguagePayload,
  id: Yup.number().required(),
});

const defaultInitialLanguage = {
  code: "",
  name: {
    hr: "",
    en: "",
  },
} as const;

const LanguageForm: React.FC<UpsertForm<Language>> = (props) => {
  const { create, update } = useUpsert(
    "languages",
    LanguageService.create,
    LanguageService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateLanguageSchema : UpdateLanguageSchema}
      initialValues={isNew ? defaultInitialLanguage : props.initialValue}
      enableReinitialize
      onSubmit={async (payload, { resetForm }) => {
        if ("id" in payload) update(payload);
        else create(payload);

        resetForm();
        props.onClose();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (name: string, e: React.ChangeEvent) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} jezik</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Unesite kod, eng naziv i prijevod jezika
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="code"
                    name="code"
                    label="Kod"
                    value={values.code}
                    onChange={change.bind(null, "code")}
                    helperText={<>{touched.code ? errors.code : ""}</>}
                    error={touched.code && Boolean(errors.code)}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="name.en"
                    name="name.en"
                    label="ENG naziv"
                    fullWidth
                    value={values.name.en}
                    helperText={<>{touched.name?.en ? errors.name?.en : ""}</>}
                    error={touched.name && Boolean(errors.name?.en)}
                    onChange={change.bind(null, "name.en")}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="name.hr"
                    name="name.hr"
                    label="Prijevod"
                    fullWidth
                    value={values.name?.hr}
                    helperText={<>{touched.name?.hr ? errors.name?.hr : ""}</>}
                    error={touched.name && Boolean(errors.name?.hr)}
                    onChange={change.bind(null, "name.hr")}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button type="submit" onClick={(e) => handleSubmit()}>
                {actionText}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default LanguageForm;
