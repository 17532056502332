import React, { useState, useEffect } from "react";
import clsx from "clsx";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import DataTable from "react-data-table-component";
import Button from "@mui/material/Button";
import { Loader } from "../../shared";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import { Formik } from "formik";
import * as Yup from "yup";
import Async from "react-select/async";
import SaveIcon from "@mui/icons-material/Save";

const columns = [
  {
    name: "Šifra",
    selector: "id",
    width: "100px",
  },
  {
    name: "Stol",
    selector: "table",
    width: "150px",
    format: (row) => `${row.table.name}`,
  },
  {
    name: "Konobar",
    selector: "waiter",
    width: "200px",
    format: (row) =>
      row.waiter ? `${row.waiter.name} ${row.waiter.surname}` : `N/A`,
  },
  {
    name: "Proizvodi",
    selector: "orderItems",
    grow: 1,
    cell: (row) => {
      return (row.orderItems || []).map((orderItem) => (
        <div>{`${orderItem.product.name} - ${orderItem.quantity}`}</div>
      ));
    },
  },
];

const NewTableSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
});
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const loadOptions = (inputValue, callback) => {
  axios.get("/api/table-modes").then((res) => {
    callback(
      res.data.results.map((pc) => ({
        label: pc.name,
        value: pc.id,
      }))
    );
  });
};

const TableDetailsContainer = (props) => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [isLoadingOrders, setOrdersLoading] = useState(true);
  const [table, setTable] = useState();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    axios(`/api/tables/${props.match.params.tableId}`).then((res) => {
      setTable(res.data);
      setLoading(false);
    });

    return () => {};
  }, [props.match.params.tableId]);

  useEffect(() => {
    axios
      .get(`/api/orders?page=1&pageSize=10`)
      .then((res) => {
        setOrders(res.data.results);
        setOrdersLoading(false);
      })
      .catch((ex) => console.error(ex));
    return () => {};
  }, []);

  const confirmTableRegistration = () => {
    axios
      .post(`/api/tables/confirm-pos-table/?tableId=${table.id}`)
      .then(() => {
        table.tableRegistration.isConfirmed = true;
        setTable(table);
      })
      .catch(() => {
        // logError
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Material-UI
        </Link>
        <Link color="inherit" href="/getting-started/installation/">
          Core
        </Link>
        <Typography color="textPrimary">Breadcrumb</Typography>
      </Breadcrumbs>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h4" gutterBottom>
              Registracija
            </Typography>
            <Formik
              validationSchema={NewTableSchema}
              initialValues={table}
              enableReinitialize={true}
              onSubmit={({ name, description, tableMode }, { resetForm }) => {
                const payload = {
                  name,
                  description,
                  tableModeId: tableMode.value,
                };

                if (!props.selected) {
                  props.onCreate(payload);
                } else {
                  props.onUpdate({ id: props.selected.id, ...payload });
                }

                resetForm();
              }}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                } = props;

                const change = (name, e) => {
                  e.persist();
                  handleChange(e);
                  setFieldTouched(name, true, false);
                };

                const changeSelect = (name, option) => {
                  // handleChange(e.value);
                  setFieldValue(name, option);
                  setFieldTouched(name, true, false);
                };

                return (
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          name="name"
                          label="Naziv"
                          fullWidth
                          value={values.name}
                          helperText={touched.name ? errors.name : ""}
                          error={touched.name && Boolean(errors.name)}
                          onChange={change.bind(null, "name")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="description"
                          name="description"
                          label="Opis"
                          fullWidth
                          value={values.description}
                          onChange={change.bind(null, "price")}
                          helperText={
                            touched.description ? errors.description : ""
                          }
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel variant="outlined" htmlFor={"tableMode"}>
                          Vrsta
                        </InputLabel>
                        <Async
                          id="tableMode"
                          defaultOptions
                          name="tableMode"
                          cacheOptions
                          loadOptions={loadOptions}
                          onChange={changeSelect.bind(null, "tableMode")}
                          value={{ value: { value: values.tableMode } }}
                        />
                      </Grid>
                    </Grid>
                    <Button
                      // variant='primary'
                      className={classes.submit}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      Save
                    </Button>
                  </React.Fragment>
                );
              }}
            </Formik>
          </Paper>
        </Grid>
        {!!table.tableRegistration && (
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography variant="h4" component="h4" gutterBottom>
                Registracija
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Kod uređaja"
                    fullWidth
                    value={table.tableRegistration.deviceToken}
                    helperText=""
                    type="readonly"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!table.tableRegistration.isConfirmed && (
                    <Button
                      // variant="primary"
                      className={classes.submit}
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={confirmTableRegistration}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      Potvrdi uređaj
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h3" component="h3" gutterBottom>
              Posljednjih 10 narudžbi
            </Typography>
            <DataTable
              columns={columns}
              data={orders}
              progressComponent={<Loader />}
              progressPending={isLoadingOrders}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default TableDetailsContainer;
