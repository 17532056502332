import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Tax,
  WithoutId,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (
  pagination: WithPagination<{ categoryNameFilter?: number }>
) => {
  const res = await axios.get<Paginated<Tax>>(
    withQueryParams("/api/taxes", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Tax>(`/api/taxes/${id}`);
  return res.data;
};

const create = async (payload: WithoutId<Tax>) => {
  const res = await axios.post<Tax>("/api/taxes", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Tax) => {
  const res = await axios.patch<Tax>(`/api/taxes/${id}`, payload);
  return res.data;
};

const del = async (payload: Tax) => {
  await axios.delete<Tax>(`/api/taxes/${payload.id}`);
  return payload;
};

const TaxService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default TaxService;
