import Paper from "@mui/material/Paper";
import { Table, UpsertAction } from "../../shared/types";
import DataTable, { GetColumns } from "../../components/DataTable/DataTable";
import { TableService } from "../../services";
import UpsertTableForm from "./UpsertTableForm";
import MenuOptions from "../../shared/MenuOptions/new";

const getColumns: GetColumns<Table> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-table",
            sourceQueryKey: "tables",
            mutationFn: () => TableService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete table");
            },
          }}
          onEdit={() =>
            open(
              <UpsertTableForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Naziv",
    selector: (row) => row.name,
  },
  {
    name: "Opis",
    selector: (row) => row.description,
  },
  {
    name: "Tip",
    selector: (row) => row.tableModeId,
    format: (row) => (row.tableModeId === 1 ? "Uslužni" : "POS"),
  },
];

const TableContainer = () => {
  // const onRowClick = (row: Table) => {
  //   navigate(`/tables/${row.id}`);
  // };

  return (
    <Paper>
      <DataTable
        title="Stolovi"
        columns={getColumns}
        queryOptions={{
          queryKey: "tables",
          queryFn: TableService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertTableForm action={UpsertAction.create} onClose={close} />
            ),
        }}
        noData="Nema dostupnih stolova"
      />
    </Paper>
  );
};

export default TableContainer;
