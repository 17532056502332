import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import {
  MutationOptions,
  QueryKey,
  useMutation,
  useQueryClient,
} from "react-query";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleDeleteMutationSuccess } from "../../utils/mutationHandlers";
import { WithId } from "../types";
import { useContainerPagination } from "../../hooks";
import { toast, Toast } from "react-toastify";

type MenuOptionsProps<T> = {
  deleteMutation: Omit<MutationOptions<WithId<T>>, "onSuccess" | "onError"> & {
    sourceQueryKey: QueryKey;
    onError?: (toast: Toast) => void;
  };
  onEdit?: () => void;
};

const MenuOptions = <T,>({ deleteMutation, onEdit }: MenuOptionsProps<T>) => {
  const client = useQueryClient();
  const { page, pageSize } = useContainerPagination();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(e.currentTarget);

  const handleDelete = () => {
    deleteItem();
    handleClose();
  };

  const handleEdit = () => {
    handleClose();
    onEdit?.();
  };

  const { mutate: deleteItem } = useMutation({
    ...deleteMutation,
    onSuccess: (data) => {
      handleDeleteMutationSuccess(data, deleteMutation.sourceQueryKey, client, {
        page,
        pageSize,
      });
    },
    onError: () => {
      deleteMutation.onError?.(toast);
    },
  });

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary="Izbriši" />
        </MenuItem>
        {onEdit && (
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Uredi" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MenuOptions;
