import axios from "axios";
import {
  ApiGetResponse as Paginated,
  WithPagination,
  Product,
} from "../shared/types";
import { withQueryParams } from "./utils";

const getAll = async (pagination: WithPagination<{ categoryId?: number }>) => {
  const res = await axios.get<Paginated<Product>>(
    withQueryParams("/api/products", pagination)
  );
  return res.data;
};

const getById = async (id: number) => {
  const res = await axios.get<Product>(`/api/products/${id}`);
  return res.data;
};

interface CreateProductPayload {
  name: string;
  description: string;
  price: number;
  sku: string;
  categories: number[];
  ingredients: number[];
  options: number[];
}

const create = async (payload: CreateProductPayload) => {
  const res = await axios.post<Product>("/api/products", payload);

  return res.data;
};

const update = async ({ id, ...payload }: Product) => {
  const res = await axios.patch<Product>(`/api/products/${id}`, payload);
  return res.data;
};

const del = async (payload: Product) => {
  await axios.delete<Product>(`/api/products/${payload.id}`);
  return payload;
};

const ProductService = {
  getAll,
  getById,
  create,
  update,
  del,
};

export default ProductService;
