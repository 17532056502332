import { Button, Typography } from "@mui/material";
import Table, { TableColumn } from "react-data-table-component";
import AddIcon from "@mui/icons-material/Add";
import { usePaginatedQuery } from "../../hooks";
import { Loader } from "../../shared";
import { UsePaginatedQueryProps } from "../../hooks/usePaginatedQuery";
import useUpsertForm, { UseUpsertFormReturn } from "../../hooks/useUpsertForm";

export type GetColumns<T, Args = {}> = (
  args: UseUpsertFormReturn & Args
) => TableColumn<T>[];

type DataTableProps<T> = {
  title: string;
  queryOptions: UsePaginatedQueryProps<T>;
  columns: ({ open, close }: UseUpsertFormReturn) => TableColumn<T>[];
  action: {
    text: string;
    handler: ({ open, close }: UseUpsertFormReturn) => void;
  };
  noData: string | JSX.Element;
};

const DataTable = <T,>({
  title,
  action,
  columns,
  queryOptions,
  noData,
}: DataTableProps<T>) => {
  const { open, close } = useUpsertForm();
  const { query, pagination } = usePaginatedQuery(queryOptions);
  const { data, isLoading } = query;
  const { pageSize, setPage, setPageSize } = pagination;

  return (
    <Table
      title={title}
      data={data?.results || []}
      columns={columns({ open, close })}
      actions={
        <Button key="add" onClick={() => action.handler({ open, close })}>
          <AddIcon />
          {action.text}
        </Button>
      }
      paginationPerPage={pageSize}
      pagination
      paginationServer
      onChangeRowsPerPage={setPageSize}
      onChangePage={setPage}
      paginationTotalRows={pageSize}
      progressComponent={<Loader />}
      progressPending={isLoading}
      noDataComponent={
        typeof noData === "string" ? (
          <Typography m={2}>{noData}</Typography>
        ) : (
          noData
        )
      }
    />
  );
};

export default DataTable;
