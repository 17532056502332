import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Category, UpsertAction } from "../../shared/types";
import DataTable, { GetColumns } from "../../components/DataTable/DataTable";
import { CategoryService } from "../../services";
import UpsertCategoryForm from "./UpsertCategoryForm";
import MenuOptions from "../../shared/MenuOptions/new";

const getColumns: GetColumns<Category> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-category",
            sourceQueryKey: "categories",
            mutationFn: () => CategoryService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete category");
            },
          }}
          onEdit={() =>
            open(
              <UpsertCategoryForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Naziv",
    selector: (row) => row.name.hr,
    width: "200px",
  },
];

const ProductCategoryContainer = () => {
  return (
    <Paper>
      <DataTable
        title="Kategorije proizvoda"
        columns={getColumns}
        queryOptions={{
          queryKey: "categories",
          queryFn: CategoryService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertCategoryForm
                action={UpsertAction.create}
                onClose={close}
              />
            ),
        }}
        noData={<Typography m={2}>Nema dostupnih kategorija</Typography>}
      />
    </Paper>
  );
};

export default ProductCategoryContainer;
