import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Option, UpsertAction } from "../../shared/types";
import { DataTable } from "../../components";
import MenuOptions from "../../shared/MenuOptions/new";
import { OptionService } from "../../services";
import { GetColumns } from "../../components/DataTable/DataTable";
import UpsertOptionForm from "./UpsertOptionFrom";

const getColumns: GetColumns<Option> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-option",
            sourceQueryKey: "options",
            mutationFn: () => OptionService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete option");
            },
          }}
          onEdit={() =>
            open(
              <UpsertOptionForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px", // custom width for icon button
  },
  {
    name: "Naziv",
    selector: (row) => row.name.hr,
  },
  {
    name: "Opis",
    selector: (row) => row.description.hr,
  },
];

const OptionsContainer = () => {
  // const handleRowClick = (row: Option) => navigate(`/options/${row.id}`);

  return (
    <Paper>
      <DataTable
        title="Opcije"
        columns={getColumns}
        queryOptions={{
          queryKey: "options",
          queryFn: OptionService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertOptionForm action={UpsertAction.create} onClose={close} />
            ),
        }}
        noData={<Typography m={2}>Nema dostupnih opcija</Typography>}
      />
    </Paper>
  );
};

export default OptionsContainer;
