import Button from "@mui/material/Button";
import { useMutation, useQueryClient } from "react-query";
import { OrderService } from "../../../../services";
import { Order } from "../../../../shared/types";

interface OrderStatusButtonProps {
  order: Order;
  statusColor: string;
  statusLabel: string;
}

const OrderStatusButton: React.FC<OrderStatusButtonProps> = ({
  order,
  statusColor,
  statusLabel,
}) => {
  const client = useQueryClient();
  const { mutate } = useMutation({
    mutationKey: "updateOrderStatus",
    mutationFn: async () =>
      OrderService.update({ ...order, status: order.status + 1 }),

    onSuccess: (order) => {
      const orders = client.getQueryData<Order[]>("orders");
      if (!orders) return client.refetchQueries("orders");

      const orderIndex = orders.findIndex((o) => o.id === order.id);

      if (orderIndex) {
        orders[orderIndex] = order;
      }

      client.setQueryData("orders", orders);
    },
  });

  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: statusColor,
        color: "#fff",
      }}
      onClick={() => mutate()}
    >
      {statusLabel}
    </Button>
  );
};

export default OrderStatusButton;
