import { useState } from "react";
import { Ingredient, Order, Option } from "../../../../shared/types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DataTable from "react-data-table-component";
import { Loader } from "../../../../shared";
import { Typography } from "@mui/material";

const language = {
  id: 0,
  code: "string",
  englishName: "string",
  displayName: "string",
};

const translation = {
  hr: "string",
  en: "string",
};

const fakeIngredient = {
  id: 0,
  name: translation,
  description: translation,
};
const fakeIngredients = [fakeIngredient, fakeIngredient, fakeIngredient];

const fakeOption = {
  id: 0,
  defaultOptionValueId: 0,
  defaultOptionValue: {
    id: 0,
    translations: [translation],
  },
  optionTranslations: [
    {
      id: 0,
      value: "string",
      language,
    },
  ],
  optionValues: [
    {
      id: 0,
      translations: [translation],
    },
  ],
};

const fakeOptions = [fakeOption, fakeOption, fakeOption];

interface ExpandableRowProps {
  data: Order[];
  isLoading: boolean;
  padding?: number;
}

const ExpandableRow = ({
  data,
  isLoading,
  padding = 0,
}: ExpandableRowProps) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e: React.SyntheticEvent, selectedTab: number) =>
    setCurrentTab(selectedTab);

  const ingredientColumns = [
    {
      name: "Naziv",
      selector: (row: Ingredient) => row.name.hr,
    },
    {
      name: "Opis",
      selector: (row: Ingredient) => row.description.hr,
    },
  ];
  const optionsColumns = [
    {
      name: "Naziv",
      selector: (row: Option) => row.name.hr,
    },
    {
      name: "Opis",
      selector: (row: Option) => row.description.hr,
    },
  ];

  return (
    <Box sx={{ width: "100%", pl: padding, pr: padding }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          <Tab label="Sastojci" />
          <Tab label="Opcije" />
        </Tabs>
      </Box>
      {currentTab === 0 && (
        <DataTable
          columns={ingredientColumns}
          data={
            // (data.orderItems[0].product.productIngredients || []).map(
            //   (productIngredient) => productIngredient.ingredient
            // )
            fakeIngredients as Ingredient[]
          }
          progressComponent={<Loader />}
          progressPending={isLoading}
          noDataComponent={
            <Typography m={2}>Nema dostupnih sastojaka</Typography>
          }
        />
      )}
      {currentTab === 1 && (
        <DataTable
          columns={optionsColumns}
          data={
            // (data.orderItems[0].product.productOptions || []).map(
            //   (productOption) => productOption.option
            // )
            fakeOptions as any as Option[]
          }
          progressComponent={<Loader />}
          progressPending={isLoading}
          noDataComponent={
            <Typography m={2}>Nema dostupnih sastojaka</Typography>
          }
        />
      )}
    </Box>
  );
};

export default ExpandableRow;
