import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }, 20);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <CircularProgress variant='determinate' value={progress} />
    </div>
  );
}
