import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { createRoot } from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import { responsiveFontSizes } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import AppProvider from "./context/ContextProvider";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import LoginContainer from "./account/LoginContainer";
import RegisterContainer from "./account/RegisterContainer";
import { PrivateRoute } from "./shared";
import SignalRProvider from "./context/SignalR";

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "react-query";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

let theme = responsiveFontSizes(
  createTheme({
    typography: {
      htmlFontSize: 16,
      fontSize: 16,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: 25,
        },
      },
    },
  })
);

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={msalInstance}>
          <SignalRProvider>
            <AppProvider>
              <Routes>
                <Route path="/login" element={<LoginContainer />} />
                <Route path="/register" element={<RegisterContainer />} />
                <Route
                  index
                  path="*"
                  element={
                    <PrivateRoute>
                      <App />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </AppProvider>
          </SignalRProvider>
        </MsalProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </BrowserRouter>
);

//// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
//// Learn more about service workers: https://cra.
serviceWorkerRegistration.unregister();

//import 'bootstrap/dist/css/bootstrap.css';
//import React from 'react';
//import { createRoot } from 'react-dom/client';
//import { BrowserRouter } from 'react-router-dom';
//import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
//const rootElement = document.getElementById('root');
//const root = createRoot(rootElement);

//root.render(
//  <BrowserRouter basename={baseUrl}>
//    <App />
//  </BrowserRouter>);

//// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
//// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

//// If you want to start measuring performance in your app, pass a function
//// to log results (for example: reportWebVitals(console.log))
//// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
