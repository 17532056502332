import { useState } from "react";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CategoryService } from "../../services";
import { useUpsert } from "../../hooks";
import { Category, UpsertAction, UpsertForm } from "../../shared/types";

const CreateCategoryPayload = {
  name: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
};
const CreateCategorySchema = Yup.object().shape(CreateCategoryPayload);
const UpdateCategorySchema = Yup.object().shape({
  ...CreateCategoryPayload,
  id: Yup.number().required(),
});

const defaultInitialCategory = {
  name: "",
};

const UpsertCategoryForm: React.FC<UpsertForm<Category>> = (props) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const { create, update } = useUpsert(
    "categories",
    CategoryService.create,
    CategoryService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateCategorySchema : UpdateCategorySchema}
      initialValues={isNew ? defaultInitialCategory : props.initialValue}
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        if ("id" in values) await update(values);
        else await create(values);

        resetForm();
        if (!keepOpen) props.onClose();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (
          name: string,
          e: React.ChangeEvent<HTMLInputElement>
        ) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} kategoriju proizvoda</DialogTitle>
            <DialogContent sx={{ width: 500 }}>
              <DialogContentText gutterBottom>
                Unesite ime kategorije proizvoda
              </DialogContentText>
              <TextField
                id="name"
                name="name"
                label="Naziv"
                fullWidth
                value={values.name}
                helperText={<>{touched.name ? errors.name : ""}</>}
                error={touched.name && Boolean(errors.name)}
                onChange={change.bind(null, "name")}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Odustani</Button>
              <Button onClick={() => handleSubmit()}>{actionText}</Button>
              {isNew && (
                <Button
                  onClick={() => {
                    handleSubmit();
                    setKeepOpen(true);
                  }}
                >
                  Spremi i dodaj novi
                </Button>
              )}
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UpsertCategoryForm;
