import { Paper, Typography, Grid } from "@mui/material";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  PieLabelRenderProps,
} from "recharts";

import axios from "axios";
import { useQuery } from "react-query";
import { Loader } from "../shared";
import { Dashboard } from "../shared/types";

const mockOrders = [
  {
    name: "piće 1",
    sold: 172,
  },
  {
    name: "piće 2",
    sold: 165,
  },
  {
    name: "piće 3",
    sold: 133,
  },
  {
    name: "piće 4",
    sold: 115,
  },
  {
    name: "piće 5",
    sold: 103,
  },
  {
    name: "piće 6",
    sold: 87,
  },
  {
    name: "piće 7",
    sold: 46,
  },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: PieLabelRenderProps) => {
  if (cx && cy && midAngle && innerRadius && outerRadius && percent) {
    innerRadius = parseInt(innerRadius + "");
    outerRadius = parseInt(outerRadius + "");
    cx = parseInt(cx + "");
    cy = parseInt(cy + "");
    midAngle = parseInt(midAngle + "");

    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  } else return null;
};

const Home = () => {
  const spacing = 2;
  const { data, isLoading, isError } = useQuery("dashboard", () =>
    axios.get<Dashboard>("/api/dashboard")
  );

  // const handleClick = () => {
  //   hubConnection.invoke('registerOrder', {});
  // };

  if (isLoading) return <Loader />;
  if (isError || !data) return <div>Failed to load data</div>;

  const { topSellers, waiterCount, orderCount, totalTableCount } = data.data;

  return (
    <>
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Kontrolna ploča
          </Typography>
          <Typography gutterBottom>
            Pregledajte poslovanje svojeg lokala
          </Typography>
        </Grid>

        <Grid item xs={12} md={3} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Narudžbe
            </Typography>
            <Typography variant="h4" gutterBottom>
              154
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              izvršene
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Promet
            </Typography>
            <Typography variant="h4" gutterBottom>
              15,478
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              kn
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Vrijeme narudžbe
            </Typography>
            <Typography variant="h4" gutterBottom>
              2.8
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              min
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Pros. vrijednost
            </Typography>
            <Typography variant="h4" gutterBottom>
              45
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              kn
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={7.5} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Najpopularniji proizvodi
            </Typography>
            <div style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={150}
                  height={40}
                  data={topSellers
                    .map(({ id, total }) => ({ name: id, sold: total }))
                    .sort((a, b) => a.name - b.name)}
                  layout="vertical"
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Bar dataKey="sold" barSize={25} fill="#413ea0" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4.5} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Prodaje po konobaru
            </Typography>
            <div style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={600} height={600}>
                  <Pie
                    data={waiterCount.map(({ waiterId, count }) => ({
                      name: waiterId,
                      count,
                    }))}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="count"
                  >
                    {mockOrders.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Narudžbe u posljednjem tjednu
            </Typography>
            <div style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={orderCount.reverse()}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="ts" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6} spacing={spacing}>
          <Paper
            square={false}
            style={{
              padding: 20,
              textAlign: "center",
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Najpopularniji stolovi
            </Typography>
            <div style={{ height: 350 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={150}
                  height={40}
                  data={totalTableCount
                    .map(({ name, count }) => ({ name, count }))
                    .sort((a, b) => a.name.localeCompare(b.name))}
                  layout="vertical"
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Bar dataKey="count" barSize={25} fill="#413ea0" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
