export type IAppContext = {
  state: {
    user: User | null;
  };
  setUser: (user: string) => void;
};
export type IAppContextState = {
  user: any;
};

export interface ApiGetResponse<T> {
  results: T[];
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
}

export type WithPagination<T> = PaginationInput & T;

export type PaginationInput = {
  page: number;
  pageSize: number;
};

export enum UpsertAction {
  create,
  update,
}

export type WithId<T> = {
  id: number;
} & T;

export type WithoutId<T> = Omit<T, "id">;

export type UpsertForm<T> = {
  onClose: () => void;
} & (
  | {
      action: UpsertAction.create;
      initialValue?: never;
    }
  | {
      action: UpsertAction.update;
      initialValue: T;
    }
);

export interface PostAndPatchFormProps<Selected> {
  open: boolean;
  selected: null | Selected;
  onCreate: (payload: any, keepOpen?: boolean) => void;
  onUpdate: (payload: any) => void;
  onClose: () => void;
  onCancel: () => void;
}

export type Translation = {
  hr: string;
  en: string;
};

export type Currency = {
  id: number;
  code: string;
  name: Translation;
};

export type OptionValue = {
  id: number;
  value: Translation;
  isDefault: boolean;
  quantity: number;
};

export type Option = {
  id: number;
  name: Translation;
  description: Translation;
  optionValues: OptionValue[];
};

export type Tax = {
  id: number;
  name: Translation;
  rate: number;
};

export type Category = {
  id: number;
  name: Translation;
};

export type ProductOption = {
  productId: number;
  optionId: number;
  option: Option;
};

export type Ingredient = {
  id: number;
  name: Translation;
  description: Translation;
};

export type Product = {
  id: number;
  sku: string;
  name: Translation;
  description: Translation;
  price: number;
  tax: Tax;
  categories: Category[];
  options: Option[];
  ingredients: Ingredient[];
};

export type ExcludedIngredient = {
  productId: number;
  ingredientId: number;
  ingredient: Ingredient;
};

export type OrderItem = {
  id: number;
  quantity: number;
  note: string;
  productId: number;
  product: Product;
  productOptions: ProductOption[];
  excludedIngredients: ExcludedIngredient[];
};

export type TableMode = {
  id: number;
  name: string;
  description: string;
};

export type RefreshToken = {
  token: string;
  expires: Date;
  isExpired: boolean;
  created: Date;
  createdByIp: string;
  revoked: Date;
  revokedByIp: string;
  replacedByToken: string;
  isActive: boolean;
};

export type User = {
  firstName: string;
  lastName: string;
  refreshTokens: RefreshToken[];
  name: string;
  id: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  passwordHash: string;
  securityStamp: string;
  concurrencyStamp: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEnd: Date;
  lockoutEnabled: boolean;
  accessFailedCount: number;
};

export type TableRegistration = {
  id: number;
  deviceToken: string;
  isConfirmed: boolean;
  userId: number;
  user: User;
  tableId: number;
};

export type Table = {
  id: number;
  name: string;
  description: string;
  tableModeId: number;
  tableMode: TableMode;
  tableRegistration: TableRegistration;
};

export type Waiter = {
  id: number;
  internalId: string;
  username: string;
  name: string;
  surname: string;
};

export interface Order {
  status: number;
  id: number;
  note: string;
  orderItems: OrderItem[];
  createdAt: Date;
  tableId: number;
  table: Table;
  waiterId: number;
  waiter: Waiter;
}

export type Language = {
  id: number;
  code: string;
  name: Translation;
};

export type Dashboard = {
  orderCount: Order[];
  waiterCount: { waiterId: number; count: number }[];
  totalTableCount: { id: number; name: string; count: number }[];
  topSellers: { id: number; total: number }[];
};
