import { Formik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Tax, UpsertAction, UpsertForm } from "../shared/types";
import { TaxService } from "../services";
import { useUpsert } from "../hooks";

const CreateTaxPayload = {
  name: Yup.string()
    .min(3, "Prekratak naziv")
    .max(50, "Predug naziv!")
    .required("Obavezno"),
  rate: Yup.number()
    .typeError("Stopa mora biti broj")
    .required("Obavezno")
    .min(0, "Stopa mora biti pozitivna")
    .max(1, "Stopa ne moze biti veca od 100%"),
};

const CreateTaxSchema = Yup.object().shape(CreateTaxPayload);
const UpdateTaxSchema = Yup.object().shape({
  ...CreateTaxPayload,
  id: Yup.number().required(),
});

const defaultInitialTax = {
  name: "",
  rate: "",
};

const TaxForm: React.FC<UpsertForm<Tax>> = (props) => {
  const { create, update } = useUpsert(
    "taxes",
    TaxService.create,
    TaxService.update
  );

  const isNew = props.action === UpsertAction.create;
  const actionText = isNew ? "Dodaj" : "Ažuriraj";

  return (
    <Formik
      validationSchema={isNew ? CreateTaxSchema : UpdateTaxSchema}
      initialValues={isNew ? defaultInitialTax : props.initialValue}
      enableReinitialize={true}
      onSubmit={async (payload, { resetForm }) => {
        if ("id" in payload) update(payload);
        else
          create({
            name: {
              hr: payload.name,
              en: "",
            },
            rate: parseFloat(payload.rate),
          });

        resetForm();
        props.onClose();
      }}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange,
        setFieldTouched,
      }) => {
        const change = (name: string, e: React.ChangeEvent) => {
          e.persist();
          handleChange(e);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open onClose={props.onClose}>
            <DialogTitle>{actionText} porez</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Unesite naziv i vrijednost poreza
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="name"
                    name="name"
                    label="Naziv"
                    fullWidth
                    value={values.name}
                    helperText={<>{touched.name ? errors.name : ""}</>}
                    error={touched.name && Boolean(errors.name)}
                    onChange={(e) => change("name", e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="rate"
                    name="rate"
                    label="Stopa"
                    fullWidth
                    value={values.rate}
                    onChange={(e) => change("rate", e)}
                    helperText={<>{touched.rate ? errors.rate : ""}</>}
                    error={touched.rate && Boolean(errors.rate)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button type="submit" onClick={() => handleSubmit()}>
                {actionText}
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default TaxForm;
