import { Formik } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { Dropdown } from "../../../../shared";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Product } from "../../../../shared/types";

type OptionOrIngredient = "ingredient" | "option";
type Mapping = {
  optionValueId: number;
  quantity: number;
};
type Payload = {
  productId: number;
  ingredientId: number;
  mappings?: Mapping[];
};

interface AddIngredientOrOptionFormProps {
  onCreate: (ingredientOrOption: OptionOrIngredient, payload: Payload) => void;
  onClose: () => void;
  open: boolean;
  productId: number;
  optionOrIngredient: OptionOrIngredient;
}

const AddIngredientOrOptionForm = (props: AddIngredientOrOptionFormProps) => {
  return (
    <Formik
      initialValues={{
        ingredients: [] as Product["ingredients"],
        options: [] as Product["options"],
      }}
      enableReinitialize
      onSubmit={(payload, { resetForm }) => {
        const { optionOrIngredient } = props;
        const key = `${optionOrIngredient}Id` as `${OptionOrIngredient}Id`;

        const finalPayload = {
          productId: props.productId,
          mappings: [
            {
              optionValueId: 0,
              quantity: 0,
            },
          ],
          [key]: payload[`${optionOrIngredient}s`].map((i) =>
            "ingredientId" in i ? i.ingredientId : i.id
          )[0],
        } as Payload;
        if (optionOrIngredient !== "option") delete finalPayload.mappings;

        props.onCreate(optionOrIngredient, finalPayload);

        resetForm();
      }}
    >
      {({
        values,

        handleSubmit,
        handleChange,
        setFieldTouched,
        setFieldValue,
      }) => {
        const changeSelect = (name: string) => (option: any) => {
          setFieldValue(name, option);
          setFieldTouched(name, true, false);
        };

        return (
          <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Proizvod</DialogTitle>
            <DialogContent>
              <DialogContentText>Uređivač proizvoda</DialogContentText>
              <Grid container spacing={2}>
                {props.optionOrIngredient === "ingredient" ? (
                  <Grid item xs={12}>
                    <Dropdown
                      dropdownFor="ingredient"
                      getOptionLabel={(option) => option.name.hr}
                      inputLabel="Sastojci"
                      onChange={changeSelect("ingredients")}
                      value={values.ingredients}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Dropdown
                      dropdownFor="option"
                      getOptionLabel={(option) => option.name.hr}
                      inputLabel="Opcije"
                      onChange={changeSelect("options")}
                      value={values.options}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Odustani</Button>
              <Button onClick={() => handleSubmit()}>Spremi</Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AddIngredientOrOptionForm;
