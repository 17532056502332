import { Paper, Typography } from "@mui/material";
import DataTable, { GetColumns } from "../components/DataTable/DataTable";
import { Tax, UpsertAction } from "../shared/types";
import MenuOptions from "../shared/MenuOptions/new";
import { TaxService } from "../services";
import UpsertTaxForm from "./UpsertTaxForm";

const getColumns: GetColumns<Tax> = ({ open, close }) => [
  {
    name: "Šifra",
    selector: (row) => row.id,
    width: "100px",
  },
  {
    cell: (row) => {
      return (
        <MenuOptions
          deleteMutation={{
            mutationKey: "delete-tax",
            sourceQueryKey: "taxes",
            mutationFn: () => TaxService.del(row),
            onError: (toast) => {
              toast.error("Failed to delete tax");
            },
          }}
          onEdit={() =>
            open(
              <UpsertTaxForm
                action={UpsertAction.update}
                onClose={close}
                initialValue={row}
              />
            )
          }
        />
      );
    },
    allowOverflow: true,
    button: true,
    width: "56px",
  },
  {
    name: "Porez",
    selector: (row) => row.name.hr,
    width: "200px",
  },
  {
    name: "Stopa",
    selector: (row) => row.rate,
    width: "200px",
  },
];

const TaxesContainer = () => {
  return (
    <Paper>
      <DataTable
        title="Porezi"
        columns={getColumns}
        queryOptions={{
          queryKey: "taxes",
          queryFn: TaxService.getAll,
        }}
        action={{
          text: "create",
          handler: ({ open, close }) =>
            open(
              <UpsertTaxForm action={UpsertAction.create} onClose={close} />
            ),
        }}
        noData={<Typography m={2}>Nema dostupnih poreza</Typography>}
      />
    </Paper>
  );
};

export default TaxesContainer;
